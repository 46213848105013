import * as React from "react";
import "../../styles/servicehomepage.scss"
import '@amzn/awsui-global-styles/polaris.css';
import {
    Box,
    Grid,
  } from '../../aws-ui-components';
  
export default () => {

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );

    return (
        <Box>
             <Grid className="custom-home__header" gridDefinition={[{ colspan: { xxs: 12 } }]}>
      <Box padding={{ vertical: 'xl' }}>
        <Grid
          gridDefinition={[
            { offset: { l: '2', xxs: '1' }, colspan: { l: '8', xxs: '10' } },
            { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
            { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
          ]}
        >
          

          <div className="custom-home__header-title">
            <Box variant="h1" fontWeight="bold" padding='0' color="inherit">
              <p fontSize='15'> Content Audit Tool </p>
              <ColoredLine color='orange'/>
            </Box>
            
            <Box variant="p" fontWeight="light">
              <span className="custom-home__header-sub-title">
              The Content Audit Tool is an internal web platform that provides an automated system for the auditing, assessing, and evaluating of blog post content for the AWS technical field.
              It allows stakeholders to track which blogs have been audited, which blogs require auditing and which need to be updated.
              </span>
            </Box>
          </div>

          
        </Grid>
      </Box>
    </Grid>
        </Box>
    );
}