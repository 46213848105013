import * as React from 'react'

import { API, graphqlOperation } from 'aws-amplify'

import {
  SpaceBetween,
  Button,
  Modal,
  Box,
  Form,
  Input,
  Select,
} from '../../../aws-ui-components'
import SimpleTable from '../../../common-components/SimpleTable.jsx'

import { reminderOptions, docStatusOptions } from '../KMSHelpers'

import * as queries from '../../../graphql/queries'

const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'did', label: 'DID', editable: false },
      { id: 'title', label: 'Title', editable: true },
      { id: 'owner', label: 'Owner', editable: true },
      { id: 'filename', label: 'Filename', editable: true },
      { id: 'location', label: 'Location', editable: true },
      { id: 'status', label: 'Status', editable: true },
      { id: 'reminderInterval', label: 'Reminder interval', editable: true }
    ]
  }
]

const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Document Identifiers' },
  { value: 30, label: '30 Document Identifiers' },
  { value: 50, label: '50 Document Identifiers' }
]

const CUSTOM_PREFERENCE_OPTIONS = [
  { value: 'table', label: 'Table' },
  { value: 'cards', label: 'Cards' }
]

const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['did', 'title', 'owner', 'filename', 'location', 'status', 'reminderInterval'],
  wrapLines: true,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
}

const COLUMN_DEFINITIONS = [
  {
    id: 'did',
    header: 'DID',
    cell: item => item.id,
    minWidth: '200px',
    sortingField: 'id'
  },
  {
    id: 'title',
    header: 'Title',
    cell: item => item.title,
    minWidth: '200px',
    sortingField: 'title'
  },
  {
    id: 'owner',
    header: 'Owner',
    cell: item => item.owner.id,
    minWidth: '50px',
    maxWidth: '100px',
    sortingField: 'owner'
  },
  {
    id: 'filename',
    header: 'Filename',
    cell: item => item.filename,
    minWidth: '100px',
    sortingField: 'filename'
  },
  {
    id: 'location',
    header: 'Location',
    cell: item => item.location,
    minWidth: '100px',
    sortingField: 'location'
  },
  {
    id: 'status',
    header: 'Status',
    cell: item => item.status,
    minWidth: '100px',
    sortingField: 'status'
  },
  {
    id: 'reminderInterval',
    header: 'Reminder interval',
    cell: item => item.reminderInterval,
    minWidth: '100px',
    sortingField: 'reminderInterval'
  }
]

class PersonalDIDTable extends React.Component {
  employeePrefix = `${this.props.employee.id}-`

  baseState = {
    registry: this.props.registry,
    business_id: '',
    workstream_id: '',
    unique_id: '',
    iterator: null,
    documentIdentifierOwnerId: this.props.employee.id,
    title: '',
    filename: null,
    location: null,
    employee: this.props.employee,
    documentIdentifiers: this.props.registry.documentIdentifiers.items,
    loading: this.props.loading,
    isOwner: this.props.isOwner,
    selectedOptionReminder: reminderOptions[0],
    selectedOptionDocStatus: docStatusOptions[0],
    resultingDID: '',
    personalDID: this.employeePrefix,
    editingDid: false,
    modalVisible: false,
    cloningDid: false,
    loading: false
  }

  state = this.baseState

  refreshRegistry = async () => {
    const registryDataRaw = await API.graphql(
      graphqlOperation(queries.getDataWithAuthorisationFunction, {
        input: {
          requesterId: this.state.employee.id,
          dataSubjectId: '',
          operation: 'getRegistry',
          params: JSON.stringify({
            registryId: this.state.registry.id
          })
        }
      })
    )
    const registryData = JSON.parse(
      registryDataRaw.data.getDataWithAuthorisationFunction.queryReturn
    )
    this.setState({ documentIdentifiers: registryData.documentIdentifiers.items, modalVisible: false, editingDid: false })
  }

  validateFields = () => {
    const { business_id, workstream_id, unique_id, documentIdentifierOwnerId, title } = this.state
    if ([business_id, workstream_id, unique_id, documentIdentifierOwnerId, title].includes('')) {
      this.setState({ error: 'Please fill in all values' })
      return false
    } else {
      this.setState({ error: '' })
      return true
    }
  }

  validateDid = () => {
    const regex = new RegExp('([A-Z, 0-9]){3}([A-Z]){2}([0-9]){4}([a-z]?)')
    const didString = this.state.resultingDID
    return regex.test(didString)
  }

  handleSubmit = async () => {
    this.setState({loading: true})
    if (this.state.editingDid === true) {
      await this.updateDid()
    } else {
      await this.onSubmit()
    }
    this.setState({loading: false})
  }

  onSubmit = async () => {
    const valid = this.validateFields()
    if (!valid) {
      return
    }

    const validDid = this.validateDid()
    if (!validDid) {
      return this.setState({ error: 'Provide a valid DID' })
    }


    // Check if DID already exists
    const result = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
      input: {
        requesterId: this.props.employee.id,
        dataSubjectId: "",
        operation: 'findDid',
        params: JSON.stringify({
          didToFind: this.state.personalDID
        })
      }
    }))

    // REFACTOR THIS LOGIC. DON'T ALLOW DUPLICATE PERSONAL DIDS
    if (result.data.getDataWithAuthorisationFunction.status === "SUCCESS") {
      const docResult = JSON.parse(result.data.getDataWithAuthorisationFunction.message)
      if (docResult.docOwner === this.state.employee.id && docResult.registryType === 'personal') {
        alert("You already have a personal DID with this value ... try a different value")
      } else {
        await this.createDid()
      }
    } else {
      await this.createDid()
    }
    await this.refreshRegistry()
  }

  createDid = async () => {
    const { personalDID, registry, title, documentIdentifierOwnerId, filename, location, selectedOptionDocStatus, selectedOptionReminder } = this.state

    await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
      input: {
        requesterId: this.state.employee?.id || employee.id,
        dataSubjectId: "",
        operation: 'createDid',
        params: JSON.stringify({
          did: personalDID,
          registryId: registry.id,
          didInfo: {
            title,
            documentIdentifierOwnerId,
            filename,
            location,
            status: selectedOptionDocStatus.value,
            reminderInterval: selectedOptionReminder.value
          }
        })
      }
    }))
  }

  updateDid = async () => {
    const { personalDID, registry, title, documentIdentifierOwnerId, filename, location, selectedOptionDocStatus, selectedOptionReminder } = this.state

    await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
      input: {
        requesterId: this.state.employee?.id || employee.id,
        dataSubjectId: "",
        operation: 'updateDid',
        params: JSON.stringify({
          did: personalDID,
          registryId: registry.id,
          didInfo: {
            title,
            documentIdentifierOwnerId,
            filename,
            location,
            status: selectedOptionDocStatus.value,
            reminderInterval: selectedOptionReminder.value
          }
        })
      }
    }))
    await this.refreshRegistry()
  }

  setEditingStateValues = (item) => {
    const coreDid = item.id.slice(this.employeePrefix.length)

    const stateValues = {
      business_id: coreDid.substring(0, 3),
      workstream_id: coreDid.substring(3, 5),
      unique_id: coreDid.substring(5, 9),
      iterator: coreDid.substring(9, 10),
      title: item.title,
      filename: item.filename,
      location: item.location,
      selectedOptionReminder: reminderOptions.find(option => option.value === item.reminderInterval),
      selectedOptionDocStatus: docStatusOptions.find(option => option.value === item.status),
      resultingDID: coreDid,
      personalDID: item.id,
      editingDid: false
    }

    this.setState(stateValues)
  }

  updateDidValue = () => {
    const resultingDID = [
      this.state.business_id,
      this.state.workstream_id,
      this.state.unique_id,
      this.state.iterator
    ]
      .filter(x => typeof x === 'string' && x.length > 0)
      .join('')

    this.setState({ resultingDID, personalDID: `${this.employeePrefix}${resultingDID}` })
  }

  onChange = (field, event, forceUpper = false, maxLength = null) => {
    var value = String(event.detail.value)
    if (forceUpper === true) { value = value.toUpperCase()}
    if (maxLength === null || value.length <= maxLength) {
      this.setState({ [field]: value }, this.updateDidValue)
    }
  }

  DidModalForm = () => {
    var headerValue = this.state.editingDid ? "Editing DID" : "Create a new DID"
    headerValue = this.state.cloningDid ? "Cloning DID" : headerValue

    return (
      <Modal
        onDismiss={() => this.setState(this.baseState)}
        visible={this.state.modalVisible}
        closeAriaLabel='Close modal'
        size='large'
        footer={
          <div>
            <Box float='left'>
              <div style={{ color: 'red' }}>{this.state.error}</div>
            </Box>
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  onClick={() => this.setState(this.baseState)}
                  variant='link'
                >
                  Cancel
                </Button>
                <Button loading={this.state.loading} variant='primary' onClick={() => this.handleSubmit()}>
                  Submit
                </Button>
              </SpaceBetween>
            </Box>
          </div>
        }
        header={headerValue}
      >
        <Form>
          {
            this.state.cloningDid === true &&
            <div style={{color: "orange"}}>!! Important !!: the system does not allow duplicate personal DIDs, so you need to adjust the DID value (which creates a new entry)</div>
          }
          {
            this.state.editingDid === false &&
            <div>
              <div style={{ marginBottom: '20px' }}>
                Business / Sector ID - e.g. 'AW1' (*):
                <Input
                  onChange={event => this.onChange('business_id', event, true, 3)}
                  value={this.state.business_id}
                />
              </div>
              <div style={{ marginBottom: '20px' }}>
                Project / Workstream ID - e.g. 'SA' (*):
                <Input
                  onChange={event => this.onChange('workstream_id', event, true, 2)}
                  value={this.state.workstream_id}
                />
              </div>
              <div style={{ marginBottom: '20px' }}>
                Unique ID - e.g. '0001' (*):
                <Input
                  type='number'
                  onChange={event => this.onChange('unique_id', event, false, 4)}
                  value={this.state.unique_id}
                />
              </div>
              <div style={{ marginBottom: '20px' }}>
                Iterator - e.g. 'a' (opt):
                <Input
                  onChange={event => this.onChange('iterator', event, false, 1)}
                  value={this.state.iterator}
                />
              </div>
            </div>
          }

          <p style={{ fontSize: '20px' }}>
            Resulting DID: {this.state.personalDID}
          </p>
          {/* <Divider/> */}
          <div style={{ marginBottom: '20px' }}>
            Title (*):
            <Input
              onChange={event => this.onChange('title', event)}
              value={this.state.title}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            Owner (*):
            <Input
              onChange={event => this.onChange('documentIdentifierOwnerId', event)}
              value={this.state.documentIdentifierOwnerId}
              disabled={true}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            Filename (opt):
            <Input
              onChange={event => this.onChange('filename', event)}
              value={this.state.filename}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            Location (opt):
            <Input
              onChange={event => this.onChange('location', event)}
              value={this.state.location}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            Status (opt):
            <div style={{ width: '50%' }}>
              <Select
                onChange={({ detail }) => {
                  this.setState({ selectedOptionDocStatus: detail.selectedOption })
                }}
                selectedOption={this.state.selectedOptionDocStatus}
                options={docStatusOptions}
                selectedAriaLabel='Selected'
              />
            </div>
          </div>
          <div style={{ marginBottom: '20px' }}>
            Validation check date (optional, send email reminder to verify
            DID state)
            <div style={{ width: '50%' }}>
              <Select
                onChange={({ detail }) => {
                  this.setState({ selectedOptionReminder: detail.selectedOption })
                }}
                selectedOption={this.state.selectedOptionReminder}
                options={reminderOptions}
                selectedAriaLabel='Selected'
              />
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  actions = selectedDistributions => {
    const isOnlyOneSelected = selectedDistributions.length === 1
    return (
      <SpaceBetween direction='horizontal' size='s'>
        {this.state.isOwner && (
          <>
            <Button
              disabled={selectedDistributions.length !== 1}
              onClick={() => {
                this.setEditingStateValues(selectedDistributions[0])
                this.setState({ modalVisible: true, editingDid: true })
              }}
            >
              Update
            </Button>
            <Button
              disabled={selectedDistributions.length !== 1}
              onClick={() => {
                this.setEditingStateValues(selectedDistributions[0])
                this.setState({ modalVisible: true, cloningDid: true })
              }}
            >
              Clone
            </Button>
          </>
        )}

        <Button
          onClick={() => this.setState({ modalVisible: true })}
          variant='primary'
        >
          Create a new DID
        </Button>
        {this.DidModalForm()}
      </SpaceBetween>
    )
  }

  render() {
    const table_params = {
      dataItems: this.state.documentIdentifiers,
      columns: COLUMN_DEFINITIONS,
      defaultPreferences: DEFAULT_PREFERENCES,
      headerActions: this.actions,
      headerTitle: `Personal registry: ${this.state.registry?.name} - Document Identifiers`,
      enableSearch: true,
      pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
      contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
      customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
      selection: 'single',
      loading: this.state.loading,
      empty: 'No documents in registry'
    }

    return <SimpleTable params={table_params} />
  }
}

export default PersonalDIDTable
