import React, { useEffect, useState } from "react";
import ServiceNavigation from '../../common-components/ServiceNavigation.jsx';
import TopNav from '../../common-components/TopNav.jsx';
import {
  AppLayout,
  BreadcrumbGroup,
  ExpandableSection,
  Grid,
  Link,
  SpaceBetween,
  Button,
  HelpPanel,
  Box,
  Icon,
  Container,
  Header
} from '../../aws-ui-components';
import { API } from "aws-amplify";
import * as queries from '../../graphql/queries';


const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'FAST',
        href: '#/'
      },
      {
        text: 'Content Audit Tool',
        href: '#/content-audit-tool'
      },
      {
        text: 'Blog Details',
        href: '#/Blog Details'
      }
    ]}
  />
);

//Content
function Content(props) {
  const data = props
  console.log(data)



  const dets = {
    title: data.props.title,
    channel: data.props.channel,
    tags: data.props.tags,
    authors: data.props.authors,
    url: data.props.url,
    pub_date: data.props.pub_date
  }

  return (

    <div>
      <div>
        <Container
          header={
            <Header
              variant="h2"
            >
            </Header>
          }
        >
          <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
            <Box
              color="inherit"
              display="block"
              float="left"
              fontSize="heading-xl"
              fontWeight="bold"
              id="blog_title"
              textAlign="left"
            >
              {dets.title}
            </Box>
            <Button variant="primary"> Create Audit </Button>
          </Grid>

          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }]}>
            <Box
              color="inherit"
              display="block"
              float="left"
              fontSize="heading-xs"
              fontWeight="light"
              id="blog_authors"
              textAlign="left"

            >
              <b> Authored by:  </b>

              {
                dets.authors.toString().replace(/["\[\]']+/g, ' ')
              }
            </Box>
            <Box
              color="inherit"
              display="block"
              float="left"
              fontSize="heading-xs"
              fontWeight="light"
              id="blog_channel"
              textAlign="left"
            >
              <b> Blog Channel: </b> {dets.channel}
            </Box>
          </Grid>

          <Grid gridDefinition={[{ colspan: 12 }]}>
            <Box
              color="inherit"
              display="block"
              float="left"
              fontSize="heading-xs"
              fontWeight="light"
              id="blog_tags"
              textAlign="left"
            >
              <b> Tags: </b> {dets.tags.toString().replace(/["\[\]']+/g, ' ')}
            </Box>
          </Grid>
          <Grid>
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href={dets.url}
            >
              View Blog
            </Link>
          </Grid>
          <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 1 }, { colspan: 3 }]}>
            <Box
              color="inherit"
              display="block"
              float="left"
              fontSize="heading-xs"
              fontWeight="light"
              id="blog_audited"
              textAlign="left"
            >
              <b>  Last Audited: - </b>
            </Box>
            <Box
              color="inherit"
              display="block"
              float="left"
              fontSize="heading-xs"
              fontWeight="light"
              id="blog_pub"
              textAlign="left"
            >
              <b>  Published Date: </b>{dets.pub_date}
            </Box>
            <Box
              color="inherit"
              display="block"
              float="right"
              fontSize="heading-xs"
              fontWeight="light"
              textAlign="left"
            >
              <b> Status: </b> {dets.status}
            </Box>
            <Box
              display="block"
              float="left"
              fontSize="heading-xs"
              fontWeight="bold"
              id="blog_status"
              textAlign="left"
            >
            </Box>
          </Grid>
        </Container>

        <SpaceBetween />

        <Container
          header={
            <Header
              variant="h2"
              description="This is a log of all the status changes of a blog"
            >
              Audit Trail
            </Header>
          }
        >
          <ExpandableSection
            variant="container"
            header={
              <Header variant="h2">
                AUDITED - 2022-02-10
              </Header>
            }
          >
            [TBD]
          </ExpandableSection>
        </Container>



      </div>

    </div>
  );
}

// Help panel content
const Tools = (
  <HelpPanel
    header={<h2>CloudFront</h2>}
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html">
              What is Amazon CloudFront?
            </a>
          </li>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/GettingStarted.html">
              Getting started
            </a>
          </li>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html">
              Working with distributions
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      Amazon CloudFront is a web service that speeds up distribution of your static and dynamic web content, such as
      .html, .css, .js, and image files, to your users.
    </p>
  </HelpPanel>
);


class BlogDetailsContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      title: '',
      channel: '',
      pub_date: '',
      url: '',
      authors: [],
      tags: [],
      audits: [],
      status: ''
    };
    this.inital_state = this.state
  };
  _isMounted = false;

  componentDidMount = async () => {
    this._isMounted = true;
    await this.getBlogData()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState = async () => {
    this.setState(this.initial_state)
    this.setState({ resetDistributions: true })
  };

  getBlogData = async () => {
    var id = this.state.id
    const blogDetails = await API.graphql({ query: queries.getBlog, variables: { id: id } })
    console.log(blogDetails)
    const data = blogDetails.data.getBlog

    if (this._isMounted) {
      this.setState({
        id: data.id,
        title: data.blogTitle,
        channel: data.channel,
        authors: data.blogAuthors,
        pub_date: data.publishedDate,
        url: data.blogLink,
        tags: data.blogTags,
        status: data.status
      })
    }
    console.log(this.state)
  }

  render() {
    return (
      <div>
        <TopNav //Top Navigation panel
        />
        <AppLayout
          navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
          breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined above
          content={<Content props={this.state} />} // Main content on the page, defined above
          contentType="default" // Sets default app layout settings for widths
          tools={Tools} // Tools panel content defined below
          navigationOpen={true}
          toolsHide={true}
        />
      </div>
    );
  }
}

export default BlogDetailsContent
