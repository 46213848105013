import React from 'react';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import env from "react-dotenv";
import { Route } from 'react-router-dom';
import ServiceHomepage from '../common-components/ServiceHomepage.jsx';
import ContentAuditTool from './content-audit-tool/ContentAuditTool.jsx';
import CustomerShadowTool from './customer-shadowing-tool/CustomerShadowTool.jsx';
import LetterOfIntentTool from './letter-of-intent-tool/LetterOfIntentTool.jsx';
import LetterOfIntentOverview from './letter-of-intent-tool/LetterOfIntentItem/LetterOfIntentOverview';
import GroupInfo from './user-group-management/GroupOverview.jsx'
import GroupDetails from './user-group-management/group-details/GroupDetails.jsx'
import ManagerOverview from './manager-tool/ManagerOverview.jsx';
import DirectReportOverview from './manager-tool/DirectReportOverview.jsx';
import DirectReportLoIView from './manager-tool/direct-report-loi/DirectReportLoIView';
import KnowledgeManagement from './kms-tool/KnowledgeManagementOverview';
import RegistryItem from './kms-tool/registry-components/RegistryItem.jsx';

import Settings from './profile-settings/Settings'

import '@amzn/awsui-global-styles/polaris.css';
import '../styles/servicehomepage.scss';
import AchievementLog from "./achievement-log/AchievementLog";
import { Button } from "../aws-ui-components";
import { GetOrCreateUser, getOrCreateUserFromSSO } from "../helpers/authHelpers";

import awsmobile from '../aws-exports.js';
import BlogDetailsContent from './content-audit-tool/BlogDetailsContent.jsx';

const REMOTE_ENVIRONMENT = !(env.REACT_ENVIRONMENT === 'local' || env.REACT_ENVIRONMENT === 'e2e-test')  // Anything not local will be treated as remote, which means we use SSO for auth
var url = ''

if (REMOTE_ENVIRONMENT) {
    const REACT_MIDWAY_DOMAIN = env.REACT_MIDWAY_DOMAIN
    const REACT_MIDWAY_REDIRECT_URL = env.REACT_MIDWAY_REDIRECT_URL
    const REACT_IDENTITY_POOL_ID = env.REACT_IDENTITY_POOL_ID
    const REACT_REGION = env.REACT_REGION
    const REACT_USER_POOL_ID = env.REACT_USER_POOL_ID
    const REACT_USER_POOL_WEB_CLIENT_ID = env.REACT_USER_POOL_WEB_CLIENT_ID

    const oauth = {
        domain: REACT_MIDWAY_DOMAIN,
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: REACT_MIDWAY_REDIRECT_URL,
        redirectSignOut: REACT_MIDWAY_REDIRECT_URL,
        responseType: 'code',
        options: {
            AdvancedSecurityDataCollectionFlag: false
        }
    };

    const config = {
        Auth: {
            identityPoolId: REACT_IDENTITY_POOL_ID,
            region: REACT_REGION,
            userPoolId: REACT_USER_POOL_ID,
            userPoolWebClientId: REACT_USER_POOL_WEB_CLIENT_ID,
            oauth: oauth,
        }
    };

    url = 'https://' + oauth.domain + '/authorize?redirect_uri=' + oauth.redirectSignIn + '&response_type=' + oauth.responseType + '&client_id=' + config.Auth.userPoolWebClientId;
    Amplify.configure(config);
} else {
    Amplify.configure(awsmobile)
}

const AuthStateApp = () => {
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        async function setUserState() {
            const user = REMOTE_ENVIRONMENT === true ? await getOrCreateUserFromSSO() : await GetOrCreateUser();
            setUser(user);
        }
        setUserState()
    }, []);


    if (user) {
        return (
            <div className="App">
                <div>
                    <Route exact path="/" component={ServiceHomepage} />
                    <Route path="/content-audit-tool" component={ContentAuditTool} />
                    <Route path="/blog-details/:id" component={BlogDetailsContent} />
                    <Route path="/customer-shadow-tool" component={CustomerShadowTool} />
                    <Route path="/letter-of-intent-tool" component={LetterOfIntentTool} />
                    <Route path="/letter-of-intent/:id" component={LetterOfIntentOverview} />
                    <Route path="/group-details/:id" render={(props) => (<GroupDetails viewOnly={true} {...props} />)} />
                    <Route path="/edit-group/:id" component={GroupDetails} />
                    <Route path="/groups" component={GroupInfo} />
                    <Route path="/manager-overview" component={ManagerOverview} />
                    <Route path="/direct-report-overview/:id" component={DirectReportOverview} />
                    <Route path="/direct-report-letter-of-intent/:employeeId/:id" component={DirectReportLoIView} />
                    <Route path="/achievement-log" component={AchievementLog} />
                    <Route path="/profile-settings" component={Settings} />
                    <Route path="/knowledge-management-system" component={KnowledgeManagement} />
                    <Route path="/knowledge-management-system-registry/:id" component={RegistryItem} />
                </div>
            </div>
        )
    } else {
        console.log('redirecting');
        let signUpForm = "";
        let sso = "";
        if (!REMOTE_ENVIRONMENT) {
            signUpForm = (
                <AmplifyAuthenticator usernameAlias='email'>
                    <AmplifySignUp headerText="Sign Up"
                        usernameAlias='email'
                        formFields={[
                            {
                                type: 'email',
                                label: 'Email',
                                placeholder: 'Email',
                                required: true
                            },
                            {
                                type: 'password',
                                label: 'Password',
                                placeholder: 'Enter Your Password',
                                required: true
                            }
                        ]}
                        slot="sign-up"
                    />
                </AmplifyAuthenticator>)
        } else {
            sso = (
                <Button iconAlign="center" variant="primary" href={url}>SSO</Button>
            )
        }
        console.log("singupform", signUpForm)

        return (
            <div className="body">
                <div align="center">
                    {signUpForm}
                    {sso}
                </div>
            </div>

        )
    };
};

export default AuthStateApp;
