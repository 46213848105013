import * as React from 'react'

import { API, graphqlOperation } from 'aws-amplify'

import {
  Button,
} from '../../../aws-ui-components'

import SimpleTable from '../../../common-components/SimpleTable.jsx'

import * as queries from '../../../graphql/queries'

const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'title', label: 'Title', editable: true },
      { id: 'requester', label: 'Requester', editable: true },
      { id: 'filename', label: 'Filename', editable: true },
      { id: 'location', label: 'Location', editable: true },
      { id: 'status', label: 'Status', editable: true },
      { id: 'reminderInterval', label: 'Reminder interval', editable: true },
      { id: 'requestStatus', label: 'Request status', editable: true }
    ]
  }
]

const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Document Identifiers' },
  { value: 30, label: '30 Document Identifiers' },
  { value: 50, label: '50 Document Identifiers' }
]

const CUSTOM_PREFERENCE_OPTIONS = [
  { value: 'table', label: 'Table' },
  { value: 'cards', label: 'Cards' }
]

const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['title', 'requester', 'filename', 'location', 'status', 'reminderInterval', 'requestStatus'],
  wrapLines: true,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
}

const COLUMN_DEFINITIONS = [
  {
    id: 'did',
    header: 'DID',
    cell: item => item.id,
    minWidth: '200px',
    sortingField: 'id'
  },
  {
    id: 'title',
    header: 'Title',
    cell: item => item.title,
    minWidth: '200px',
    sortingField: 'title'
  },
  {
    id: 'requester',
    header: 'Requester',
    cell: item => item.requester.id,
    minWidth: '50px',
    maxWidth: '100px',
    sortingField: 'requester'
  },
  {
    id: 'filename',
    header: 'Filename',
    cell: item => item.filename,
    minWidth: '100px',
    sortingField: 'filename'
  },
  {
    id: 'location',
    header: 'Location',
    cell: item => item.location,
    minWidth: '100px',
    sortingField: 'location'
  },
  {
    id: 'status',
    header: 'Status',
    cell: item => item.status,
    minWidth: '100px',
    sortingField: 'status'
  },
  {
    id: 'reminderInterval',
    header: 'Reminder interval',
    cell: item => item.reminderInterval,
    minWidth: '100px',
    sortingField: 'reminderInterval'
  },
  {
    id: 'requestStatus',
    header: 'Request Status',
    cell: item => item.requestStatus,
    minWidth: '100px',
    sortingField: 'requestStatus'
  },
]

class DIDRequestTable extends React.Component {
  state = {
    employee: this.props.employee,
    registry: this.props.registry,
    canManage: this.props.canManage,
    loading: false
  }

  declineDidRequestRequest = async (item) => {
    this.setState({loading: true})
    const result = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
      input: {
        requesterId: this.state.employee.id,
        dataSubjectId: "",
        operation: 'declineDidRequest',
        params: JSON.stringify({
          registryId: this.state.registry.id,
          didInfo: {
            didRequestId: item.id,
            documentIdentifierRequestRequesterId: item.requester.id,
          }
        })
      }
    }))
    await this.props.refreshRegistry()
    this.setState({loading: false})
  }

  deleteDidRequest = async (item) => {
    this.setState({loading: true})
    const result = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
      input: {
        requesterId: this.state.employee.id,
        dataSubjectId: "",
        operation: 'deleteDidRequest',
        params: JSON.stringify({
          requestId: item.id
        })
      }
    }))
    await this.props.refreshRegistry()
    this.setState({loading: false})
  }

  actions = selectedDistributions => {
    const isOnlyOneSelected = selectedDistributions.length === 1
    return (
      <>
        {
          this.state.canManage &&
          <Button
            disabled={selectedDistributions.length !== 1}
            onClick={() => {this.declineDidRequest(selectedDistributions[0])}}
            loading={this.state.loading}
          >
            Decline
          </Button>
        }
        {
          this.state.employee.id === selectedDistributions[0]?.requester.id
          && selectedDistributions[0]?.requestStatus === 'requested' &&
          <Button
            disabled={selectedDistributions.length !== 1}
            onClick={() => {this.deleteDidRequest(selectedDistributions[0])}}
            loading={this.state.loading}
          >
            Delete
          </Button>
        }
      </>
    )
  }

  render() {
    const table_params = {
      dataItems: this.props.didRequests.filter(item => item.requestStatus !== "cancelled"),
      columns: COLUMN_DEFINITIONS,
      defaultPreferences: DEFAULT_PREFERENCES,
      headerActions: this.actions,
      headerTitle: `DID Requests`,
      enableSearch: true,
      pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
      contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
      customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
      selection: 'single',
      loading: this.state.loading,
      empty: 'No requests for registry'
    }

    return <SimpleTable params={table_params} />
  }
}

export default DIDRequestTable
