import * as React from "react";
import {
    Grid,
    SpaceBetween,
    Button,
    Modal,
    Box,
    Multiselect,
    DatePicker,
    ButtonDropdown
} from '../../aws-ui-components';
import { Link } from 'react-router-dom';
import { API} from "aws-amplify";
import * as queries from '../../graphql/queries';
import SimpleTable from '../../common-components/SimpleTable.jsx';
import { CSVLink } from "react-csv";
import { SERVICES, BLOG_CHANNELS } from './helpers/exports';

const CONTENT_SELECTOR_OPTIONS = [
    {
        label: 'Main distribution properties',
        options: [
            { id: 'title', label: 'Title', editable: false },
            { id: 'channel', label: 'Blog Channel', editable: true },
            { id: 'createdDate', label: 'Published', editable: true },
            { id: 'audited', label: 'Last Audited', editable: true },
            { id: 'status', label: 'Status', editable: true },
            { id: 'url', label: 'URL', editable: true },
        ]
    }
];

const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Blogs' },
    { value: 30, label: '30 Blogs' },
    { value: 50, label: '50 Blogs' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }];

export const DEFAULT_PREFERENCES = {
    pageSize: 10,
    visibleContent: ['title', 'channel', 'published', 'audited', 'status','url'],
    wrapLines: false,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'title',
        header: 'Title',
        cell: item => <Link to={{ pathname: `/blog-details/${item.id}` }}>{item.blogTitle}</Link>,
        minWidth: 180,
        sortingField: 'title'
    },
    {
        id: 'channel',
        header: 'Blog Channel',
        cell: item => item.channel,
        minWidth: 40,
        sortingField: 'channel'
    },
    {
        id: 'published',
        header: 'Published',
        cell: item => new Date(item.publishedDate).toLocaleDateString('en-UK'),
        minWidth: 40,
        maxWidth: 100,
        sortingField: 'published'
    },
    {
        id: 'audited',
        header: 'Audited',
        cell: item => item.auditDate,
        minWidth: 40,
        sortingField: 'audited'
    },
    {
        id: 'status',
        header: 'Status',
        cell: item => item.status,
        minWidth: 100,
        sortingField: 'status'
    },
    {
        id: 'url',
        header: 'URL',
        cell: item => <Link href={item.blogLink}> {item.blogLink} </Link>,
        minWidth: 100,
        sortingField: 'url'
    },
];

class BlogTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            error: "",
            modalVisible: false,
            exportModalVisible: false,
            resetDistributions: false,
            blogs: [],
            loading: true,
            //form values
            selectedBlogChannel: [],
            pubStartDate: "",
            pubEndDate: "",
            auditStartDate: "",
            auditEndDate: "",
            updateStartDate: "",
            updateEndDate: "",
            selectedServices: []
        };
        this.inital_state = this.state
    };
    _isMounted = false;

    componentDidMount = async () => {
        this.setState({ loading: true })
        this._isMounted = true;
        await this.onSubmit()
        this.setState({ loading: false })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    onSubmit = async () => {
        if (!this.state.loading === true) { this.setState({ loading: true }) }
        const { selectedBlogChannel,pubStartDate, pubEndDate, auditStartDate, auditEndDate, selectedServices } = this.state
        let defaultdates = { defaultStart: '1990-01-01', defaultEnd: '9999-12-12' }
        let fullFilter = {
            or: [],
            and: []
        }

        if (selectedBlogChannel.length !== 0) {
            selectedBlogChannel.forEach((x, i) => {
                fullFilter.or.push({ channel: { eq: (x.value) } })
                console.log(fullFilter)
            }
            );
        } else {
            //default all
            BLOG_CHANNELS.forEach((x, i) => {
                fullFilter.or.push({ channel: { eq: (x.value) } })
            }
            );
        }


        if (selectedServices !== null) {
            selectedServices.forEach((x, i) => {
                fullFilter.and.push({ blogTags: { contains: (x.label) } })
            }
            );
        }


        if (pubStartDate !== "" || pubEndDate !== "") {
            fullFilter.and.push({ publishedDate: { between: [pubStartDate, pubEndDate] } })
        } else {
            //default all
            fullFilter.and.push({ publishedDate: { between: [defaultdates.defaultStart, defaultdates.defaultEnd] } })

        }


        if (auditStartDate !== "" || auditEndDate !== "") {
            fullFilter.and.push({ auidtDate: { between: [auditStartDate, auditEndDate] } })
        }

        let filteredBlogs = await API.graphql({ query: queries.listBlogs, variables: { filter: fullFilter, limit: 5000 } })
        const data = filteredBlogs.data.listBlogs.items

        console.log(fullFilter)
        console.log(data)

        if (this._isMounted) {
            this.setState({
                blogs: data,
                loading: false,
                modalVisible: false
            })
        }

    }

    updateState = async () => {
        this.setState(this.initial_state)
        this.setState({ resetDistributions: true })
    };

    onChange = (field, event) => {
        this.setState({ [field]: event.detail.value })
    };

    onFilter = (field, data) => {
        this.setState({ [field]: data })
    }


    actions = (selectedDistributions) => {
        const exportmodalOptions = (
            <div>
                <CSVLink data={this.state.blogs}>
                    Results
                </CSVLink>
            </div>
        )

        const modalOptions = (
            <div>
                <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
                    <div>  <p> <b> Blog Channel: </b> </p> </div>
                    <div>
                        <Multiselect
                            selectedOptions={this.state.selectedBlogChannel}
                            onChange={({ detail }) =>
                                this.setState({ 'selectedBlogChannel': detail.selectedOptions })
                            }
                            deselectAriaLabel={e => `Remove ${e.label}`}
                            options={[
                                {
                                    label: "AWS DBS ",
                                    options: BLOG_CHANNELS,
                                    value: "dbs-group",
                                    disabled: false
                                }
                            ]}
                            placeholder="Choose options"
                            selectedAriaLabel="Selected"
                        />
                    </div>
                </Grid>
                <Grid
                    gridDefinition={[{ colspan: 2 }, { colspan: 5 }, { colspan: 5 }]}
                >
                    <div> <b> Published Date: </b></div>
                    <div>
                        <DatePicker
                            onChange={(event) => this.onChange('pubStartDate', event)}
                            value={this.state.pubStartDate}
                            openCalendarAriaLabel={selectedDate =>
                                "Choose Date" +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="Start Date"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                    </div>
                    <div>
                        <DatePicker
                            onChange={(event) => this.onChange('pubEndDate', event)}
                            value={this.state.pubEndDate}
                            openCalendarAriaLabel={selectedDate =>
                                "Choose Date" +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="End Date"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                    </div>
                </Grid>
                <Grid
                    gridDefinition={[{ colspan: 2 }, { colspan: 5 }, { colspan: 5 }]}
                >
                    <div>  <b> Last Audited: </b> </div>

                    <div>
                        <DatePicker
                            onChange={(event) => this.onChange('auditStartDate', event)}
                            value={this.state.auditStartDate}
                            openCalendarAriaLabel={selectedDate =>
                                "Choose Date" +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="Start Date"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                    </div>
                    <div>
                        <DatePicker
                            onChange={(event) => this.onChange('auditEndDate', event)}
                            value={this.state.auditEndDate}
                            openCalendarAriaLabel={selectedDate =>
                                "Choose Date" +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="End Date"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                    </div>
                </Grid>
                <Grid
                    gridDefinition={[{ colspan: 2 }, { colspan: 5 }, { colspan: 5 }]}
                >
                    <div><b><p> Last Updated: </p></b></div>
                    <div>
                        <div>
                            <DatePicker
                                onChange={(event) => this.onChange('updateStartDate', event)}
                                value={this.state.updateStartDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                        ? `, selected date is ${selectedDate}`
                                        : "")
                                }
                                nextMonthAriaLabel="Next month"
                                placeholder="Start Date"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                            />
                        </div>
                    </div>
                    <div>
                        <DatePicker
                            onChange={(event) => this.onChange('updateEndDate', event)}
                            value={this.state.updateEndDate}
                            openCalendarAriaLabel={selectedDate =>
                                "Choose Date" +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="End Date"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                    </div>
                </Grid>
                <Grid
                    gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}
                >
                    <div><b><p> Services: </p></b></div>
                    <div>
                        <Multiselect

                            selectedOptions={this.state.selectedServices}
                            onChange={(event) =>
                                this.setState({ 'selectedServices': event.detail.selectedOptions })
                            }
                            deselectAriaLabel={e => `Remove ${e.label}`}
                            options={SERVICES}
                            placeholder="Choose services"
                            selectedAriaLabel="Selected"
                        />
                    </div>
                </Grid>
            </div>
        );
        return (

            <SpaceBetween direction="horizontal" size="s">

                <ButtonDropdown
                    disabled={selectedDistributions.length !== 1}
                    items={[
                        { text: "Assign", id: "assign", disabled: false },
                        { text: "Create Audit Report", id: "audit", disabled: false },
                    ]}
                >
                    Quick Actions
                </ButtonDropdown>


                <div>

                    <Button variant="primary" onClick={async () => { this.updateState(); this.setState({ modalVisible: true }) }}
                    >
                        Filter
                    </Button>
                    <Modal
                        onDismiss={() => this.setState({ modalVisible: false })}
                        visible={this.state.modalVisible}
                        closeAriaLabel="Close modal"
                        size="large"
                        footer={
                            <div>
                                <Box float="left">
                                    <div style={{ color: 'red' }}>{this.state.error}</div>
                                </Box>
                                <Box float="right">
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button onClick={() => this.setState({ modalVisible: false })} variant="link">Cancel</Button>
                                        <Button variant="primary" onClick={this.onSubmit}>Submit</Button>
                                    </SpaceBetween>
                                </Box>
                            </div>
                        }
                        header="Select parameters to filter your search"
                    >
                        {modalOptions}
                    </Modal>
                </div>

                <Button onClick={async () => { this.updateState(); this.setState({ exportModalVisible: true }) }} variant="primary" >
                    Export as CSV
                </Button>
                <Modal
                    onDismiss={() => this.setState({ exportModalVisible: false })}
                    visible={this.state.exportModalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{ color: 'red' }}>{this.state.error}</div>
                            </Box>
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button onClick={() => this.setState({ exportModalVisible: false })} variant="link">Cancel</Button>
                                    <Button variant="primary" onClick={this.onSubmit}>Submit</Button>
                                </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header="Export Results to CSV"
                >
                    {exportmodalOptions}
                </Modal>

            </SpaceBetween>

        );
    }

    render() {
        const table_params = {
            dataItems: this.state.blogs,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerActions: this.actions,
            enableSearch: true,
            headerTitle: "AWS Blogs",
            filterLabel: "Find blog",
            filterPlaceholder: "Find blog",
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            selection: "single",
            loading: this.state.loading
        };
        return (
            <SimpleTable params={table_params} />
        );
    }
}

export default BlogTable
