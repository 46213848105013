/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDataWithAuthorisationFunction = /* GraphQL */ `
  query GetDataWithAuthorisationFunction(
    $input: getDataWithAuthorisationFunctionInput
  ) {
    getDataWithAuthorisationFunction(input: $input) {
      status
      message
      queryReturn
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  query SendEmail($input: sendEmailInput) {
    sendEmail(input: $input) {
      status
      message
      queryReturn
    }
  }
`;
export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      blogTitle
      blogLink
      channel
      publishedDate
      auditDate
      status
      blogAuthors
      blogTags
      truncatedUrl
      createdAt
      updatedAt
      ContentAudits {
        items {
          id
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          blogId
          blog {
            id
            blogTitle
            blogLink
            channel
            publishedDate
            auditDate
            status
            blogAuthors
            blogTags
            truncatedUrl
            createdAt
            updatedAt
          }
          startDate
          completeDate
          timeToLive
          createdAt
          updatedAt
          status
          verdict
          completionComment
          owner
        }
        nextToken
      }
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        blogTitle
        blogLink
        channel
        publishedDate
        auditDate
        status
        blogAuthors
        blogTags
        truncatedUrl
        createdAt
        updatedAt
        ContentAudits {
          items {
            id
            employeeId
            blogId
            startDate
            completeDate
            timeToLive
            createdAt
            updatedAt
            status
            verdict
            completionComment
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getContentAudit = /* GraphQL */ `
  query GetContentAudit($id: ID!) {
    getContentAudit(id: $id) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      blogId
      blog {
        id
        blogTitle
        blogLink
        channel
        publishedDate
        auditDate
        status
        blogAuthors
        blogTags
        truncatedUrl
        createdAt
        updatedAt
        ContentAudits {
          items {
            id
            employeeId
            blogId
            startDate
            completeDate
            timeToLive
            createdAt
            updatedAt
            status
            verdict
            completionComment
            owner
          }
          nextToken
        }
      }
      startDate
      completeDate
      timeToLive
      createdAt
      updatedAt
      status
      verdict
      completionComment
      owner
    }
  }
`;
export const listContentAudits = /* GraphQL */ `
  query ListContentAudits(
    $filter: ModelContentAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        blogId
        blog {
          id
          blogTitle
          blogLink
          channel
          publishedDate
          auditDate
          status
          blogAuthors
          blogTags
          truncatedUrl
          createdAt
          updatedAt
          ContentAudits {
            nextToken
          }
        }
        startDate
        completeDate
        timeToLive
        createdAt
        updatedAt
        status
        verdict
        completionComment
        owner
      }
      nextToken
    }
  }
`;
export const listAuditsByEmployeeId = /* GraphQL */ `
  query ListAuditsByEmployeeId(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContentAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditsByEmployeeId(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        blogId
        blog {
          id
          blogTitle
          blogLink
          channel
          publishedDate
          auditDate
          status
          blogAuthors
          blogTags
          truncatedUrl
          createdAt
          updatedAt
          ContentAudits {
            nextToken
          }
        }
        startDate
        completeDate
        timeToLive
        createdAt
        updatedAt
        status
        verdict
        completionComment
        owner
      }
      nextToken
    }
  }
`;
export const listAuditsByBlog = /* GraphQL */ `
  query ListAuditsByBlog(
    $blogId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContentAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditsByBlog(
      blogId: $blogId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        blogId
        blog {
          id
          blogTitle
          blogLink
          channel
          publishedDate
          auditDate
          status
          blogAuthors
          blogTags
          truncatedUrl
          createdAt
          updatedAt
          ContentAudits {
            nextToken
          }
        }
        startDate
        completeDate
        timeToLive
        createdAt
        updatedAt
        status
        verdict
        completionComment
        owner
      }
      nextToken
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      email
      firstName
      lastName
      managerId
      manager {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      level
      role
      groupMemberships {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      groupManagerships {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          actionsAllowed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listEmployeesByManagerId = /* GraphQL */ `
  query ListEmployeesByManagerId(
    $managerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeesByManagerId(
      managerId: $managerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      description
      groupManagerId
      groupManager {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subGroups {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          parentGroupId
          parentGroup {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      goals {
        items {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      members {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      additionalGroupManagers {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          actionsAllowed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listByGroupManagerId = /* GraphQL */ `
  query ListByGroupManagerId(
    $groupManagerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByGroupManagerId(
      groupManagerId: $groupManagerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGroupMember = /* GraphQL */ `
  query GetGroupMember($id: ID!) {
    getGroupMember(id: $id) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGroupMembers = /* GraphQL */ `
  query ListGroupMembers(
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGroupManager = /* GraphQL */ `
  query GetGroupManager($id: ID!) {
    getGroupManager(id: $id) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      actionsAllowed
      createdAt
      updatedAt
    }
  }
`;
export const listGroupManagers = /* GraphQL */ `
  query ListGroupManagers(
    $filter: ModelGroupManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        actionsAllowed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listManagersByGroupId = /* GraphQL */ `
  query ListManagersByGroupId(
    $groupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManagersByGroupId(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        actionsAllowed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAdditionalManagersByEmployeeId = /* GraphQL */ `
  query ListAdditionalManagersByEmployeeId(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdditionalManagersByEmployeeId(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        actionsAllowed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubGroup = /* GraphQL */ `
  query GetSubGroup($id: ID!) {
    getSubGroup(id: $id) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      parentGroupId
      parentGroup {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSubGroups = /* GraphQL */ `
  query ListSubGroups(
    $filter: ModelSubGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        parentGroupId
        parentGroup {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listSubGroupsByGroupId = /* GraphQL */ `
  query ListSubGroupsByGroupId(
    $groupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubGroupsByGroupId(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        parentGroupId
        parentGroup {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLetterOfIntent = /* GraphQL */ `
  query GetLetterOfIntent($id: ID!) {
    getLetterOfIntent(id: $id) {
      id
      name
      introduction
      superPowers
      growthAreas
      summary
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      startDate
      endDate
      goals {
        items {
          id
          goalId
          goal {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          letterOfIntentId
          letterOfIntent {
            id
            name
            introduction
            superPowers
            growthAreas
            summary
            employeeId
            startDate
            endDate
            draftMode
            createdAt
            updatedAt
            owner
          }
          amount
          addedByLambda
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      draftMode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLetterOfIntents = /* GraphQL */ `
  query ListLetterOfIntents(
    $filter: ModelLetterOfIntentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLetterOfIntents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        introduction
        superPowers
        growthAreas
        summary
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        startDate
        endDate
        goals {
          items {
            id
            goalId
            letterOfIntentId
            amount
            addedByLambda
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        draftMode
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listLoIByEmployeeId = /* GraphQL */ `
  query ListLoIByEmployeeId(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLetterOfIntentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoIByEmployeeId(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        introduction
        superPowers
        growthAreas
        summary
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        startDate
        endDate
        goals {
          items {
            id
            goalId
            letterOfIntentId
            amount
            addedByLambda
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        draftMode
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGoal = /* GraphQL */ `
  query GetGoal($id: ID!) {
    getGoal(id: $id) {
      id
      name
      description
      leadershipPrinciples
      roleGuidelines
      amount
      startDate
      endDate
      goalType
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      mandatory
      deactivated
      draft
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals(
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listGoalsByEmployeeId = /* GraphQL */ `
  query ListGoalsByEmployeeId(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoalsByEmployeeId(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGoalCategory = /* GraphQL */ `
  query GetGoalCategory($id: ID!) {
    getGoalCategory(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGoalCategories = /* GraphQL */ `
  query ListGoalCategories(
    $filter: ModelGoalCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoalCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLetterOfIntentGoal = /* GraphQL */ `
  query GetLetterOfIntentGoal($id: ID!) {
    getLetterOfIntentGoal(id: $id) {
      id
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      letterOfIntentId
      letterOfIntent {
        id
        name
        introduction
        superPowers
        growthAreas
        summary
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        startDate
        endDate
        goals {
          items {
            id
            goalId
            letterOfIntentId
            amount
            addedByLambda
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        draftMode
        createdAt
        updatedAt
        owner
      }
      amount
      addedByLambda
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLetterOfIntentGoals = /* GraphQL */ `
  query ListLetterOfIntentGoals(
    $filter: ModelLetterOfIntentGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLetterOfIntentGoals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        letterOfIntentId
        letterOfIntent {
          id
          name
          introduction
          superPowers
          growthAreas
          summary
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          startDate
          endDate
          goals {
            nextToken
          }
          draftMode
          createdAt
          updatedAt
          owner
        }
        amount
        addedByLambda
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const loiGoalByGoalId = /* GraphQL */ `
  query LoiGoalByGoalId(
    $goalId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLetterOfIntentGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    loiGoalByGoalId(
      goalId: $goalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        letterOfIntentId
        letterOfIntent {
          id
          name
          introduction
          superPowers
          growthAreas
          summary
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          startDate
          endDate
          goals {
            nextToken
          }
          draftMode
          createdAt
          updatedAt
          owner
        }
        amount
        addedByLambda
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const loiGoalByLoiId = /* GraphQL */ `
  query LoiGoalByLoiId(
    $letterOfIntentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLetterOfIntentGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    loiGoalByLoiId(
      letterOfIntentId: $letterOfIntentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        letterOfIntentId
        letterOfIntent {
          id
          name
          introduction
          superPowers
          growthAreas
          summary
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          startDate
          endDate
          goals {
            nextToken
          }
          draftMode
          createdAt
          updatedAt
          owner
        }
        amount
        addedByLambda
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getExemptGoal = /* GraphQL */ `
  query GetExemptGoal($id: ID!) {
    getExemptGoal(id: $id) {
      id
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      reason
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listExemptGoals = /* GraphQL */ `
  query ListExemptGoals(
    $filter: ModelExemptGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExemptGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        reason
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const loiExemptGoalByGoalId = /* GraphQL */ `
  query LoiExemptGoalByGoalId(
    $goalId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelexemptGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    loiExemptGoalByGoalId(
      goalId: $goalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        reason
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listExemptGoalsByEmployeeId = /* GraphQL */ `
  query ListExemptGoalsByEmployeeId(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelexemptGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExemptGoalsByEmployeeId(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        reason
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAchievement = /* GraphQL */ `
  query GetAchievement($id: ID!) {
    getAchievement(id: $id) {
      id
      name
      description
      amount
      startDate
      endDate
      highlighted
      leadershipPrinciples
      roleGuidelines
      impact
      stakeholders
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      loiGoalId
      loiGoal {
        id
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        letterOfIntentId
        letterOfIntent {
          id
          name
          introduction
          superPowers
          growthAreas
          summary
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          startDate
          endDate
          goals {
            nextToken
          }
          draftMode
          createdAt
          updatedAt
          owner
        }
        amount
        addedByLambda
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAchievements = /* GraphQL */ `
  query ListAchievements(
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        amount
        startDate
        endDate
        highlighted
        leadershipPrinciples
        roleGuidelines
        impact
        stakeholders
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        loiGoalId
        loiGoal {
          id
          goalId
          goal {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          letterOfIntentId
          letterOfIntent {
            id
            name
            introduction
            superPowers
            growthAreas
            summary
            employeeId
            startDate
            endDate
            draftMode
            createdAt
            updatedAt
            owner
          }
          amount
          addedByLambda
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listAchievementsByEmployeeId = /* GraphQL */ `
  query ListAchievementsByEmployeeId(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievementsByEmployeeId(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        amount
        startDate
        endDate
        highlighted
        leadershipPrinciples
        roleGuidelines
        impact
        stakeholders
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        loiGoalId
        loiGoal {
          id
          goalId
          goal {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          letterOfIntentId
          letterOfIntent {
            id
            name
            introduction
            superPowers
            growthAreas
            summary
            employeeId
            startDate
            endDate
            draftMode
            createdAt
            updatedAt
            owner
          }
          amount
          addedByLambda
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listAchievementsByGoalId = /* GraphQL */ `
  query ListAchievementsByGoalId(
    $goalId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievementsByGoalId(
      goalId: $goalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        amount
        startDate
        endDate
        highlighted
        leadershipPrinciples
        roleGuidelines
        impact
        stakeholders
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        loiGoalId
        loiGoal {
          id
          goalId
          goal {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          letterOfIntentId
          letterOfIntent {
            id
            name
            introduction
            superPowers
            growthAreas
            summary
            employeeId
            startDate
            endDate
            draftMode
            createdAt
            updatedAt
            owner
          }
          amount
          addedByLambda
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGroupInvitees = /* GraphQL */ `
  query GetGroupInvitees($alias: String!, $groupId: ID!) {
    getGroupInvitees(alias: $alias, groupId: $groupId) {
      alias
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroupInvitees = /* GraphQL */ `
  query ListGroupInvitees(
    $alias: String
    $groupId: ModelIDKeyConditionInput
    $filter: ModelGroupInviteesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGroupInvitees(
      alias: $alias
      groupId: $groupId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        alias
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupMembersAllowListByGroup = /* GraphQL */ `
  query GroupMembersAllowListByGroup(
    $groupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupInviteesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GroupMembersAllowListByGroup(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        alias
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRegistry = /* GraphQL */ `
  query GetRegistry($id: ID!) {
    getRegistry(id: $id) {
      id
      name
      description
      documentIdentifiers {
        items {
          id
          title
          owner {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          filename
          location
          status
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          reminderInterval
          createdAt
          updatedAt
          registryDocumentIdentifiersId
          documentIdentifierOwnerId
        }
        nextToken
      }
      documentIdentifierRequests {
        items {
          id
          title
          requester {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          filename
          location
          status
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          reminderInterval
          requestStatus
          createdAt
          updatedAt
          registryDocumentIdentifierRequestsId
          documentIdentifierRequestRequesterId
          owner
        }
        nextToken
      }
      registryType
      members {
        items {
          id
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          role
          createdAt
          updatedAt
          registryMembersId
        }
        nextToken
      }
      registryOwner {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      registryRegistryOwnerId
    }
  }
`;
export const listRegistries = /* GraphQL */ `
  query ListRegistries(
    $filter: ModelRegistryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      nextToken
    }
  }
`;
export const getRegistryMembership = /* GraphQL */ `
  query GetRegistryMembership($id: ID!) {
    getRegistryMembership(id: $id) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      role
      createdAt
      updatedAt
      registryMembersId
    }
  }
`;
export const listRegistryMemberships = /* GraphQL */ `
  query ListRegistryMemberships(
    $filter: ModelRegistryMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistryMemberships(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        registry {
          id
          name
          description
          documentIdentifiers {
            nextToken
          }
          documentIdentifierRequests {
            nextToken
          }
          registryType
          members {
            nextToken
          }
          registryOwner {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          registryRegistryOwnerId
        }
        role
        createdAt
        updatedAt
        registryMembersId
      }
      nextToken
    }
  }
`;
export const listRegistryMembershipByEmployee = /* GraphQL */ `
  query ListRegistryMembershipByEmployee(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRegistryMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistryMembershipByEmployee(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        registry {
          id
          name
          description
          documentIdentifiers {
            nextToken
          }
          documentIdentifierRequests {
            nextToken
          }
          registryType
          members {
            nextToken
          }
          registryOwner {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          registryRegistryOwnerId
        }
        role
        createdAt
        updatedAt
        registryMembersId
      }
      nextToken
    }
  }
`;
export const getDocumentIdentifier = /* GraphQL */ `
  query GetDocumentIdentifier($id: ID!) {
    getDocumentIdentifier(id: $id) {
      id
      title
      owner {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      filename
      location
      status
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      reminderInterval
      createdAt
      updatedAt
      registryDocumentIdentifiersId
      documentIdentifierOwnerId
    }
  }
`;
export const listDocumentIdentifiers = /* GraphQL */ `
  query ListDocumentIdentifiers(
    $filter: ModelDocumentIdentifierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentIdentifiers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        owner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        filename
        location
        status
        registry {
          id
          name
          description
          documentIdentifiers {
            nextToken
          }
          documentIdentifierRequests {
            nextToken
          }
          registryType
          members {
            nextToken
          }
          registryOwner {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          registryRegistryOwnerId
        }
        reminderInterval
        createdAt
        updatedAt
        registryDocumentIdentifiersId
        documentIdentifierOwnerId
      }
      nextToken
    }
  }
`;
export const getDocumentIdentifierRequest = /* GraphQL */ `
  query GetDocumentIdentifierRequest($id: ID!) {
    getDocumentIdentifierRequest(id: $id) {
      id
      title
      requester {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      filename
      location
      status
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      reminderInterval
      requestStatus
      createdAt
      updatedAt
      registryDocumentIdentifierRequestsId
      documentIdentifierRequestRequesterId
      owner
    }
  }
`;
export const listDocumentIdentifierRequests = /* GraphQL */ `
  query ListDocumentIdentifierRequests(
    $filter: ModelDocumentIdentifierRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentIdentifierRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        requester {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        filename
        location
        status
        registry {
          id
          name
          description
          documentIdentifiers {
            nextToken
          }
          documentIdentifierRequests {
            nextToken
          }
          registryType
          members {
            nextToken
          }
          registryOwner {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          registryRegistryOwnerId
        }
        reminderInterval
        requestStatus
        createdAt
        updatedAt
        registryDocumentIdentifierRequestsId
        documentIdentifierRequestRequesterId
        owner
      }
      nextToken
    }
  }
`;
export const getKnowledgeManagementAdmins = /* GraphQL */ `
  query GetKnowledgeManagementAdmins($id: ID!) {
    getKnowledgeManagementAdmins(id: $id) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const listKnowledgeManagementAdmins = /* GraphQL */ `
  query ListKnowledgeManagementAdmins(
    $filter: ModelKnowledgeManagementAdminsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKnowledgeManagementAdmins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listKMAdminsByEmployee = /* GraphQL */ `
  query ListKMAdminsByEmployee(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelKnowledgeManagementAdminsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKMAdminsByEmployee(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
