//label is visible, value is code
export const SERVICES = 
    [
        {
            label: "Analytics ",
            options: [
                { label: "Amazon Athena", value: "1" },
                { label: "Amazon CloudSearch", value: "2" },
                { label: "AWS Data Exchange", value: "3" },
                { label: "Amazon Data Pipeline", value: "4" },
                { label: "Amazon OpenSearch Service (successor to Amazon Elasticsearch Service)", value: "5" },
                { label: "Amazon EMR", value: "6" },
                { label: "Amazon FinSpace", value: "7" },
                { label: "AWS Glue", value: "8" },
                { label: "Amazon Kinesis", value: "9" },
                { label: "AWS Lake Formation", value: "10" },
                { label: "Amazon Managed Streaming for Apache Kafka (Amazon MSK)", value: "11" },
                { label: "Amazon QuickSight", value: "12" },
                { label: "Amazon Redshift", value: "13" },
                { label: "Kinesis Data Analytics", value: "14" },

            ],
            value: "analytics-services",
        }
    ]


//label is visible on front end, value is code
export const BLOG_CHANNELS = [
    { label: "Database", value: "database" },
    { label: "BigData/Analytics", value: "big-data" },
    { label: "Machine Learning", value: "machine-learning" }

];