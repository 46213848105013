import { API, graphqlOperation } from 'aws-amplify';
import React from 'react';
import {
// import the components that you use in the file here
AppLayout,
BreadcrumbGroup,
SpaceBetween,
} from '../../aws-ui-components';
import ServiceNavigation from '../../common-components/ServiceNavigation';

import { GetOrCreateUser } from '../../helpers/authHelpers'

import PublicRegistryTable from './overview-components/PublicRegistryTable';
import PersonalRegistryTable from './overview-components/PersonalRegistryTable';
import KMSSearchComponent from './overview-components/KMSSearchComponent';

class KnowledgeManagement extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            employee: null,
        }
    }

    /**
     * Groups I manage (ADD GROUP)
     * Groups I'm part of
     */
    async componentDidMount() {
        const employee = await GetOrCreateUser()
        this.setState({employee: employee})
    }

    render() {
        const Breadcrumbs = () => (
            <BreadcrumbGroup
            items={[
                {
                text: 'FAST',
                href: '#/'
                },
                {
                text: 'Knowledge Management System',
                href: '#/knowledge-management-system'
                }
            ]}
            />
        );

        // Main content area (fill it in with components!)
        const Content = () => (
            <SpaceBetween size="m">
                <KMSSearchComponent employee={this.state.employee}/>
                <PublicRegistryTable employee={this.state.employee}/>
                <PersonalRegistryTable employee={this.state.employee}/>
            </SpaceBetween>
        )

        return (
            <AppLayout
            navigation={<ServiceNavigation />}
            breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
            content={<Content />} // Main content on the page, defined below
            contentType="default" // Sets default app layout settings for widths
            navigationOpen={true}
            toolsHide={true}
            />
        );
    }
}

// Breadcrumb content


export default KnowledgeManagement