import * as React from "react";
import { Link } from 'react-router-dom';

import { API, graphqlOperation } from "aws-amplify";

import { SpaceBetween, Button, Modal, Box, Form, Input, Textarea, FormField } from '../../../aws-ui-components';
import SimpleTable from '../../../common-components/SimpleTable.jsx';

import { GetOrCreateUser } from '../../../helpers/authHelpers'

import * as queries from '../../../graphql/queries'

const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'name', label: 'Name', editable: false },
      { id: 'description', label: 'Description', editable: true },
      { id: 'registryOwnership', label: 'Librarians', editable: true },
      { id: 'registryType', label: 'Registry Type', editable: true }
    ]
  }
];

const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Registries' },
  { value: 30, label: '30 Registries' },
  { value: 50, label: '50 Registries' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['name', 'description', 'registryLibrarians', 'registryOwnership', 'registryType'],
  wrapLines: true,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
  {
    id: 'name',
    header: 'Name',
    cell: item => <Link to={{ pathname: `/knowledge-management-system-registry/${item.id}` }} registry={item}>{item.name}</Link>,
    minWidth: 200,
    sortingField: 'name'
  },
  {
    id: 'description',
    header: 'Description',
    cell: item => item.description,
    minWidth: 50,
    maxWidth: 100,
    sortingField: 'owner'
  },
  {
    id: 'registryLibrarians',
    header: 'Librarians',
    cell: item => item.members.items.filter(member => member.role === 'librarian').map(librarian => librarian.employeeId).join(", "),
    minWidth: 100,
    sortingField: 'registryLibrarians'
  },
  {
    id: 'registryOwnership',
    header: 'Owner',
    cell: item => item.registryOwner.id,
    minWidth: 100,
    sortingField: 'registryOwnership'
  },
  {
    id: 'registryType',
    header: 'Registry Type',
    cell: item => item.registryType,
    minWidth: 100,
    sortingField: 'registryType'
  }
];

class PublicRegistryTable extends React.Component {
  state = {
    registryName: null,
    registryDescription: null,
    registryLibrarians: [],
    employee: this.props.employee,
    error: null,
    registries: [],
    // registryType: null,
    buttonLoading: false,
    dataLoading: false,
    isKMAdmin: false
  };
  initial_state = this.state

  componentDidMount = () => {
    this.updateState()
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  updateState = async () => {
    this.setState({ dataLoading: true })

    let employee;
    if (this.state.employee == null) { employee = await GetOrCreateUser() }

    const kmAdminData = await API.graphql(graphqlOperation(queries.listKMAdminsByEmployee, {
      employeeId: this.state.employee?.id || employee.id
    }))

    const isKMAdmin = kmAdminData.data.listKMAdminsByEmployee.items.length > 0

    const registryDataRaw = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
      input: {
        requesterId: this.state.employee?.id || employee.id,
        dataSubjectId: "",
        operation: 'getPublicRegistries',
      }
    }))

    const registryDataRaw2 = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
      input: {
        requesterId: this.state.employee?.id || employee.id,
        dataSubjectId: "",
        operation: 'getPersonalRegistries',
      }
    }))
    const registries = JSON.parse(registryDataRaw.data.getDataWithAuthorisationFunction.queryReturn)
    this.setState({ registries: registries, dataLoading: false, isKMAdmin })
  }

  stringToArray = (string) => {
    const string_without_spaces = string.replace(/\s/g, '');
    return string_without_spaces.split(",")
  }

  validateOwners = async (aliasString) => {
    if (aliasString === "") { return false }
    const alias_array = this.stringToArray(aliasString)
    var can_add = []
    var cannot_add = []
    for (const alias of alias_array) {
      const employee = await API.graphql(graphqlOperation(queries.getEmployee, { id: alias }))
      if (employee.data.getEmployee !== null) {
        can_add.push(alias)
      } else {
        cannot_add.push(alias)
      }
    }
    return { CanAdd: can_add, CannotAdd: cannot_add }
  }

  validateFields = () => {
    const { registryName, registryLibrarians } = this.state
    if ([registryName, registryLibrarians].includes("")) {
      this.setState({ error: "Please fill in all values" })
      return false
    }
    else {
      this.setState({ error: "" })
      return true
    }
  }

  onSubmit = async () => {
    this.setState({ buttonLoading: true })
    const valid = this.validateFields()
    if (!valid) { return }
    const { registryName, registryDescription, registryLibrarians, registryType } = this.state
    const createRegistry = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
      input: {
        requesterId: this.state.employee?.id || employee.id,
        dataSubjectId: "",
        operation: 'createRegistry',
        params: JSON.stringify({
          registryName: registryName,
          registryDescription: registryDescription,
          registryLibrariansString: registryLibrarians,
          registryType: 'group_public'
        })
      }
    }))
    this.setState({ modalVisible: false, buttonLoading: false })
    this.updateState()
  }

  onChange = (field, event) => {
    this.setState({ [field]: event.detail.value })
  }

  actions = (selectedDistributions) => {
    return (
      <SpaceBetween direction="horizontal" size="s">
        {this.state.isKMAdmin &&
          <Button onClick={() => this.setState({ modalVisible: true })} variant="primary" >
            Create a new Public registry
          </Button>
        }
        <Modal
          onDismiss={() => this.setState({ modalVisible: false })}
          visible={this.state.modalVisible}
          closeAriaLabel="Close modal"
          size="large"
          footer={
            <div>
              <Box float="left">
                <div style={{ color: 'red' }}>{this.state.error}</div>
              </Box>
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button onClick={() => this.setState({ modalVisible: false })} variant="link">Cancel</Button>
                  <Button variant="primary" onClick={this.onSubmit} loading={this.state.buttonLoading}>Submit</Button>
                </SpaceBetween>
              </Box>
            </div>
          }
          header="Create a new Registry"
        >
          <Form>
            <div style={{ marginBottom: '20px' }}>
              Registry name (*):
              <Input
                onChange={(event) => this.onChange('registryName', event)}
                value={this.state.registryName}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              Registry Description (opt):
              <Textarea
                onChange={(event) => this.onChange('registryDescription', event)}
                value={this.state.registryDescription}
              />
            </div>
            <FormField label="Librarian employee aliases (comma delimited) (*):" >
              <Input
                onChange={(event) => this.onChange('registryLibrarians', event)}
                value={this.state.registryType === 'personal' ? null : this.state.registryLibrarians}
                disabled={this.state.registryType === 'personal'}
              />
            </FormField>
          </Form>
        </Modal>
      </SpaceBetween>
    );
  }

  render() {
    const table_params = {
      dataItems: this.state.registries,
      columns: COLUMN_DEFINITIONS,
      defaultPreferences: DEFAULT_PREFERENCES,
      headerActions: this.actions,
      headerTitle: "All Public Document Registries",
      enableSearch: true,
      pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
      contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
      customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
      selection: "single",
      loading: this.state.dataLoading,
      empty: "No registries found"
    }

    return (
      <SimpleTable params={table_params} />
    );
  }
}

export default PublicRegistryTable