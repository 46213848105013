/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBlog = /* GraphQL */ `
  mutation CreateBlog(
    $input: CreateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    createBlog(input: $input, condition: $condition) {
      id
      blogTitle
      blogLink
      channel
      publishedDate
      auditDate
      status
      blogAuthors
      blogTags
      truncatedUrl
      createdAt
      updatedAt
      ContentAudits {
        items {
          id
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          blogId
          blog {
            id
            blogTitle
            blogLink
            channel
            publishedDate
            auditDate
            status
            blogAuthors
            blogTags
            truncatedUrl
            createdAt
            updatedAt
          }
          startDate
          completeDate
          timeToLive
          createdAt
          updatedAt
          status
          verdict
          completionComment
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog(
    $input: UpdateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    updateBlog(input: $input, condition: $condition) {
      id
      blogTitle
      blogLink
      channel
      publishedDate
      auditDate
      status
      blogAuthors
      blogTags
      truncatedUrl
      createdAt
      updatedAt
      ContentAudits {
        items {
          id
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          blogId
          blog {
            id
            blogTitle
            blogLink
            channel
            publishedDate
            auditDate
            status
            blogAuthors
            blogTags
            truncatedUrl
            createdAt
            updatedAt
          }
          startDate
          completeDate
          timeToLive
          createdAt
          updatedAt
          status
          verdict
          completionComment
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog(
    $input: DeleteBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    deleteBlog(input: $input, condition: $condition) {
      id
      blogTitle
      blogLink
      channel
      publishedDate
      auditDate
      status
      blogAuthors
      blogTags
      truncatedUrl
      createdAt
      updatedAt
      ContentAudits {
        items {
          id
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          blogId
          blog {
            id
            blogTitle
            blogLink
            channel
            publishedDate
            auditDate
            status
            blogAuthors
            blogTags
            truncatedUrl
            createdAt
            updatedAt
          }
          startDate
          completeDate
          timeToLive
          createdAt
          updatedAt
          status
          verdict
          completionComment
          owner
        }
        nextToken
      }
    }
  }
`;
export const createContentAudit = /* GraphQL */ `
  mutation CreateContentAudit(
    $input: CreateContentAuditInput!
    $condition: ModelContentAuditConditionInput
  ) {
    createContentAudit(input: $input, condition: $condition) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      blogId
      blog {
        id
        blogTitle
        blogLink
        channel
        publishedDate
        auditDate
        status
        blogAuthors
        blogTags
        truncatedUrl
        createdAt
        updatedAt
        ContentAudits {
          items {
            id
            employeeId
            blogId
            startDate
            completeDate
            timeToLive
            createdAt
            updatedAt
            status
            verdict
            completionComment
            owner
          }
          nextToken
        }
      }
      startDate
      completeDate
      timeToLive
      createdAt
      updatedAt
      status
      verdict
      completionComment
      owner
    }
  }
`;
export const updateContentAudit = /* GraphQL */ `
  mutation UpdateContentAudit(
    $input: UpdateContentAuditInput!
    $condition: ModelContentAuditConditionInput
  ) {
    updateContentAudit(input: $input, condition: $condition) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      blogId
      blog {
        id
        blogTitle
        blogLink
        channel
        publishedDate
        auditDate
        status
        blogAuthors
        blogTags
        truncatedUrl
        createdAt
        updatedAt
        ContentAudits {
          items {
            id
            employeeId
            blogId
            startDate
            completeDate
            timeToLive
            createdAt
            updatedAt
            status
            verdict
            completionComment
            owner
          }
          nextToken
        }
      }
      startDate
      completeDate
      timeToLive
      createdAt
      updatedAt
      status
      verdict
      completionComment
      owner
    }
  }
`;
export const deleteContentAudit = /* GraphQL */ `
  mutation DeleteContentAudit(
    $input: DeleteContentAuditInput!
    $condition: ModelContentAuditConditionInput
  ) {
    deleteContentAudit(input: $input, condition: $condition) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      blogId
      blog {
        id
        blogTitle
        blogLink
        channel
        publishedDate
        auditDate
        status
        blogAuthors
        blogTags
        truncatedUrl
        createdAt
        updatedAt
        ContentAudits {
          items {
            id
            employeeId
            blogId
            startDate
            completeDate
            timeToLive
            createdAt
            updatedAt
            status
            verdict
            completionComment
            owner
          }
          nextToken
        }
      }
      startDate
      completeDate
      timeToLive
      createdAt
      updatedAt
      status
      verdict
      completionComment
      owner
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      managerId
      manager {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      level
      role
      groupMemberships {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      groupManagerships {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          actionsAllowed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      managerId
      manager {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      level
      role
      groupMemberships {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      groupManagerships {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          actionsAllowed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      managerId
      manager {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      level
      role
      groupMemberships {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      groupManagerships {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          actionsAllowed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      description
      groupManagerId
      groupManager {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subGroups {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          parentGroupId
          parentGroup {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      goals {
        items {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      members {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      additionalGroupManagers {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          actionsAllowed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      description
      groupManagerId
      groupManager {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subGroups {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          parentGroupId
          parentGroup {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      goals {
        items {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      members {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      additionalGroupManagers {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          actionsAllowed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      description
      groupManagerId
      groupManager {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      subGroups {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          parentGroupId
          parentGroup {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      goals {
        items {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      members {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      additionalGroupManagers {
        items {
          id
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          actionsAllowed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGroupMember = /* GraphQL */ `
  mutation CreateGroupMember(
    $input: CreateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    createGroupMember(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateGroupMember = /* GraphQL */ `
  mutation UpdateGroupMember(
    $input: UpdateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    updateGroupMember(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteGroupMember = /* GraphQL */ `
  mutation DeleteGroupMember(
    $input: DeleteGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    deleteGroupMember(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGroupManager = /* GraphQL */ `
  mutation CreateGroupManager(
    $input: CreateGroupManagerInput!
    $condition: ModelGroupManagerConditionInput
  ) {
    createGroupManager(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      actionsAllowed
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupManager = /* GraphQL */ `
  mutation UpdateGroupManager(
    $input: UpdateGroupManagerInput!
    $condition: ModelGroupManagerConditionInput
  ) {
    updateGroupManager(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      actionsAllowed
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupManager = /* GraphQL */ `
  mutation DeleteGroupManager(
    $input: DeleteGroupManagerInput!
    $condition: ModelGroupManagerConditionInput
  ) {
    deleteGroupManager(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      actionsAllowed
      createdAt
      updatedAt
    }
  }
`;
export const createSubGroup = /* GraphQL */ `
  mutation CreateSubGroup(
    $input: CreateSubGroupInput!
    $condition: ModelSubGroupConditionInput
  ) {
    createSubGroup(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      parentGroupId
      parentGroup {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSubGroup = /* GraphQL */ `
  mutation UpdateSubGroup(
    $input: UpdateSubGroupInput!
    $condition: ModelSubGroupConditionInput
  ) {
    updateSubGroup(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      parentGroupId
      parentGroup {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSubGroup = /* GraphQL */ `
  mutation DeleteSubGroup(
    $input: DeleteSubGroupInput!
    $condition: ModelSubGroupConditionInput
  ) {
    deleteSubGroup(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      parentGroupId
      parentGroup {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createLetterOfIntent = /* GraphQL */ `
  mutation CreateLetterOfIntent(
    $input: CreateLetterOfIntentInput!
    $condition: ModelLetterOfIntentConditionInput
  ) {
    createLetterOfIntent(input: $input, condition: $condition) {
      id
      name
      introduction
      superPowers
      growthAreas
      summary
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      startDate
      endDate
      goals {
        items {
          id
          goalId
          goal {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          letterOfIntentId
          letterOfIntent {
            id
            name
            introduction
            superPowers
            growthAreas
            summary
            employeeId
            startDate
            endDate
            draftMode
            createdAt
            updatedAt
            owner
          }
          amount
          addedByLambda
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      draftMode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLetterOfIntent = /* GraphQL */ `
  mutation UpdateLetterOfIntent(
    $input: UpdateLetterOfIntentInput!
    $condition: ModelLetterOfIntentConditionInput
  ) {
    updateLetterOfIntent(input: $input, condition: $condition) {
      id
      name
      introduction
      superPowers
      growthAreas
      summary
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      startDate
      endDate
      goals {
        items {
          id
          goalId
          goal {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          letterOfIntentId
          letterOfIntent {
            id
            name
            introduction
            superPowers
            growthAreas
            summary
            employeeId
            startDate
            endDate
            draftMode
            createdAt
            updatedAt
            owner
          }
          amount
          addedByLambda
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      draftMode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLetterOfIntent = /* GraphQL */ `
  mutation DeleteLetterOfIntent(
    $input: DeleteLetterOfIntentInput!
    $condition: ModelLetterOfIntentConditionInput
  ) {
    deleteLetterOfIntent(input: $input, condition: $condition) {
      id
      name
      introduction
      superPowers
      growthAreas
      summary
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      startDate
      endDate
      goals {
        items {
          id
          goalId
          goal {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          letterOfIntentId
          letterOfIntent {
            id
            name
            introduction
            superPowers
            growthAreas
            summary
            employeeId
            startDate
            endDate
            draftMode
            createdAt
            updatedAt
            owner
          }
          amount
          addedByLambda
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      draftMode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGoal = /* GraphQL */ `
  mutation CreateGoal(
    $input: CreateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    createGoal(input: $input, condition: $condition) {
      id
      name
      description
      leadershipPrinciples
      roleGuidelines
      amount
      startDate
      endDate
      goalType
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      mandatory
      deactivated
      draft
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateGoal = /* GraphQL */ `
  mutation UpdateGoal(
    $input: UpdateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    updateGoal(input: $input, condition: $condition) {
      id
      name
      description
      leadershipPrinciples
      roleGuidelines
      amount
      startDate
      endDate
      goalType
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      mandatory
      deactivated
      draft
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal(
    $input: DeleteGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    deleteGoal(input: $input, condition: $condition) {
      id
      name
      description
      leadershipPrinciples
      roleGuidelines
      amount
      startDate
      endDate
      goalType
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      mandatory
      deactivated
      draft
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGoalCategory = /* GraphQL */ `
  mutation CreateGoalCategory(
    $input: CreateGoalCategoryInput!
    $condition: ModelGoalCategoryConditionInput
  ) {
    createGoalCategory(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateGoalCategory = /* GraphQL */ `
  mutation UpdateGoalCategory(
    $input: UpdateGoalCategoryInput!
    $condition: ModelGoalCategoryConditionInput
  ) {
    updateGoalCategory(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteGoalCategory = /* GraphQL */ `
  mutation DeleteGoalCategory(
    $input: DeleteGoalCategoryInput!
    $condition: ModelGoalCategoryConditionInput
  ) {
    deleteGoalCategory(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createLetterOfIntentGoal = /* GraphQL */ `
  mutation CreateLetterOfIntentGoal(
    $input: CreateLetterOfIntentGoalInput!
    $condition: ModelLetterOfIntentGoalConditionInput
  ) {
    createLetterOfIntentGoal(input: $input, condition: $condition) {
      id
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      letterOfIntentId
      letterOfIntent {
        id
        name
        introduction
        superPowers
        growthAreas
        summary
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        startDate
        endDate
        goals {
          items {
            id
            goalId
            letterOfIntentId
            amount
            addedByLambda
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        draftMode
        createdAt
        updatedAt
        owner
      }
      amount
      addedByLambda
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLetterOfIntentGoal = /* GraphQL */ `
  mutation UpdateLetterOfIntentGoal(
    $input: UpdateLetterOfIntentGoalInput!
    $condition: ModelLetterOfIntentGoalConditionInput
  ) {
    updateLetterOfIntentGoal(input: $input, condition: $condition) {
      id
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      letterOfIntentId
      letterOfIntent {
        id
        name
        introduction
        superPowers
        growthAreas
        summary
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        startDate
        endDate
        goals {
          items {
            id
            goalId
            letterOfIntentId
            amount
            addedByLambda
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        draftMode
        createdAt
        updatedAt
        owner
      }
      amount
      addedByLambda
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLetterOfIntentGoal = /* GraphQL */ `
  mutation DeleteLetterOfIntentGoal(
    $input: DeleteLetterOfIntentGoalInput!
    $condition: ModelLetterOfIntentGoalConditionInput
  ) {
    deleteLetterOfIntentGoal(input: $input, condition: $condition) {
      id
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      letterOfIntentId
      letterOfIntent {
        id
        name
        introduction
        superPowers
        growthAreas
        summary
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        startDate
        endDate
        goals {
          items {
            id
            goalId
            letterOfIntentId
            amount
            addedByLambda
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        draftMode
        createdAt
        updatedAt
        owner
      }
      amount
      addedByLambda
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createExemptGoal = /* GraphQL */ `
  mutation CreateExemptGoal(
    $input: CreateExemptGoalInput!
    $condition: ModelExemptGoalConditionInput
  ) {
    createExemptGoal(input: $input, condition: $condition) {
      id
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      reason
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateExemptGoal = /* GraphQL */ `
  mutation UpdateExemptGoal(
    $input: UpdateExemptGoalInput!
    $condition: ModelExemptGoalConditionInput
  ) {
    updateExemptGoal(input: $input, condition: $condition) {
      id
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      reason
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteExemptGoal = /* GraphQL */ `
  mutation DeleteExemptGoal(
    $input: DeleteExemptGoalInput!
    $condition: ModelExemptGoalConditionInput
  ) {
    deleteExemptGoal(input: $input, condition: $condition) {
      id
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      reason
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAchievement = /* GraphQL */ `
  mutation CreateAchievement(
    $input: CreateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    createAchievement(input: $input, condition: $condition) {
      id
      name
      description
      amount
      startDate
      endDate
      highlighted
      leadershipPrinciples
      roleGuidelines
      impact
      stakeholders
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      loiGoalId
      loiGoal {
        id
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        letterOfIntentId
        letterOfIntent {
          id
          name
          introduction
          superPowers
          growthAreas
          summary
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          startDate
          endDate
          goals {
            nextToken
          }
          draftMode
          createdAt
          updatedAt
          owner
        }
        amount
        addedByLambda
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAchievement = /* GraphQL */ `
  mutation UpdateAchievement(
    $input: UpdateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    updateAchievement(input: $input, condition: $condition) {
      id
      name
      description
      amount
      startDate
      endDate
      highlighted
      leadershipPrinciples
      roleGuidelines
      impact
      stakeholders
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      loiGoalId
      loiGoal {
        id
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        letterOfIntentId
        letterOfIntent {
          id
          name
          introduction
          superPowers
          growthAreas
          summary
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          startDate
          endDate
          goals {
            nextToken
          }
          draftMode
          createdAt
          updatedAt
          owner
        }
        amount
        addedByLambda
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAchievement = /* GraphQL */ `
  mutation DeleteAchievement(
    $input: DeleteAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    deleteAchievement(input: $input, condition: $condition) {
      id
      name
      description
      amount
      startDate
      endDate
      highlighted
      leadershipPrinciples
      roleGuidelines
      impact
      stakeholders
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      goalId
      goal {
        id
        name
        description
        leadershipPrinciples
        roleGuidelines
        amount
        startDate
        endDate
        goalType
        groupId
        group {
          id
          name
          description
          groupManagerId
          groupManager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          subGroups {
            nextToken
          }
          goals {
            nextToken
          }
          members {
            nextToken
          }
          additionalGroupManagers {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employeeId
        employee {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        mandatory
        deactivated
        draft
        createdAt
        updatedAt
        owner
      }
      loiGoalId
      loiGoal {
        id
        goalId
        goal {
          id
          name
          description
          leadershipPrinciples
          roleGuidelines
          amount
          startDate
          endDate
          goalType
          groupId
          group {
            id
            name
            description
            groupManagerId
            createdAt
            updatedAt
            owner
          }
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          mandatory
          deactivated
          draft
          createdAt
          updatedAt
          owner
        }
        letterOfIntentId
        letterOfIntent {
          id
          name
          introduction
          superPowers
          growthAreas
          summary
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          startDate
          endDate
          goals {
            nextToken
          }
          draftMode
          createdAt
          updatedAt
          owner
        }
        amount
        addedByLambda
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGroupInvitees = /* GraphQL */ `
  mutation CreateGroupInvitees(
    $input: CreateGroupInviteesInput!
    $condition: ModelGroupInviteesConditionInput
  ) {
    createGroupInvitees(input: $input, condition: $condition) {
      alias
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupInvitees = /* GraphQL */ `
  mutation UpdateGroupInvitees(
    $input: UpdateGroupInviteesInput!
    $condition: ModelGroupInviteesConditionInput
  ) {
    updateGroupInvitees(input: $input, condition: $condition) {
      alias
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupInvitees = /* GraphQL */ `
  mutation DeleteGroupInvitees(
    $input: DeleteGroupInviteesInput!
    $condition: ModelGroupInviteesConditionInput
  ) {
    deleteGroupInvitees(input: $input, condition: $condition) {
      alias
      groupId
      group {
        id
        name
        description
        groupManagerId
        groupManager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        subGroups {
          items {
            id
            groupId
            parentGroupId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            leadershipPrinciples
            roleGuidelines
            amount
            startDate
            endDate
            goalType
            groupId
            employeeId
            mandatory
            deactivated
            draft
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        members {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        additionalGroupManagers {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRegistry = /* GraphQL */ `
  mutation CreateRegistry(
    $input: CreateRegistryInput!
    $condition: ModelRegistryConditionInput
  ) {
    createRegistry(input: $input, condition: $condition) {
      id
      name
      description
      documentIdentifiers {
        items {
          id
          title
          owner {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          filename
          location
          status
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          reminderInterval
          createdAt
          updatedAt
          registryDocumentIdentifiersId
          documentIdentifierOwnerId
        }
        nextToken
      }
      documentIdentifierRequests {
        items {
          id
          title
          requester {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          filename
          location
          status
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          reminderInterval
          requestStatus
          createdAt
          updatedAt
          registryDocumentIdentifierRequestsId
          documentIdentifierRequestRequesterId
          owner
        }
        nextToken
      }
      registryType
      members {
        items {
          id
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          role
          createdAt
          updatedAt
          registryMembersId
        }
        nextToken
      }
      registryOwner {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      registryRegistryOwnerId
    }
  }
`;
export const updateRegistry = /* GraphQL */ `
  mutation UpdateRegistry(
    $input: UpdateRegistryInput!
    $condition: ModelRegistryConditionInput
  ) {
    updateRegistry(input: $input, condition: $condition) {
      id
      name
      description
      documentIdentifiers {
        items {
          id
          title
          owner {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          filename
          location
          status
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          reminderInterval
          createdAt
          updatedAt
          registryDocumentIdentifiersId
          documentIdentifierOwnerId
        }
        nextToken
      }
      documentIdentifierRequests {
        items {
          id
          title
          requester {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          filename
          location
          status
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          reminderInterval
          requestStatus
          createdAt
          updatedAt
          registryDocumentIdentifierRequestsId
          documentIdentifierRequestRequesterId
          owner
        }
        nextToken
      }
      registryType
      members {
        items {
          id
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          role
          createdAt
          updatedAt
          registryMembersId
        }
        nextToken
      }
      registryOwner {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      registryRegistryOwnerId
    }
  }
`;
export const deleteRegistry = /* GraphQL */ `
  mutation DeleteRegistry(
    $input: DeleteRegistryInput!
    $condition: ModelRegistryConditionInput
  ) {
    deleteRegistry(input: $input, condition: $condition) {
      id
      name
      description
      documentIdentifiers {
        items {
          id
          title
          owner {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          filename
          location
          status
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          reminderInterval
          createdAt
          updatedAt
          registryDocumentIdentifiersId
          documentIdentifierOwnerId
        }
        nextToken
      }
      documentIdentifierRequests {
        items {
          id
          title
          requester {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          filename
          location
          status
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          reminderInterval
          requestStatus
          createdAt
          updatedAt
          registryDocumentIdentifierRequestsId
          documentIdentifierRequestRequesterId
          owner
        }
        nextToken
      }
      registryType
      members {
        items {
          id
          employeeId
          employee {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          registry {
            id
            name
            description
            registryType
            createdAt
            updatedAt
            registryRegistryOwnerId
          }
          role
          createdAt
          updatedAt
          registryMembersId
        }
        nextToken
      }
      registryOwner {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      registryRegistryOwnerId
    }
  }
`;
export const createRegistryMembership = /* GraphQL */ `
  mutation CreateRegistryMembership(
    $input: CreateRegistryMembershipInput!
    $condition: ModelRegistryMembershipConditionInput
  ) {
    createRegistryMembership(input: $input, condition: $condition) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      role
      createdAt
      updatedAt
      registryMembersId
    }
  }
`;
export const updateRegistryMembership = /* GraphQL */ `
  mutation UpdateRegistryMembership(
    $input: UpdateRegistryMembershipInput!
    $condition: ModelRegistryMembershipConditionInput
  ) {
    updateRegistryMembership(input: $input, condition: $condition) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      role
      createdAt
      updatedAt
      registryMembersId
    }
  }
`;
export const deleteRegistryMembership = /* GraphQL */ `
  mutation DeleteRegistryMembership(
    $input: DeleteRegistryMembershipInput!
    $condition: ModelRegistryMembershipConditionInput
  ) {
    deleteRegistryMembership(input: $input, condition: $condition) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      role
      createdAt
      updatedAt
      registryMembersId
    }
  }
`;
export const createDocumentIdentifier = /* GraphQL */ `
  mutation CreateDocumentIdentifier(
    $input: CreateDocumentIdentifierInput!
    $condition: ModelDocumentIdentifierConditionInput
  ) {
    createDocumentIdentifier(input: $input, condition: $condition) {
      id
      title
      owner {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      filename
      location
      status
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      reminderInterval
      createdAt
      updatedAt
      registryDocumentIdentifiersId
      documentIdentifierOwnerId
    }
  }
`;
export const updateDocumentIdentifier = /* GraphQL */ `
  mutation UpdateDocumentIdentifier(
    $input: UpdateDocumentIdentifierInput!
    $condition: ModelDocumentIdentifierConditionInput
  ) {
    updateDocumentIdentifier(input: $input, condition: $condition) {
      id
      title
      owner {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      filename
      location
      status
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      reminderInterval
      createdAt
      updatedAt
      registryDocumentIdentifiersId
      documentIdentifierOwnerId
    }
  }
`;
export const deleteDocumentIdentifier = /* GraphQL */ `
  mutation DeleteDocumentIdentifier(
    $input: DeleteDocumentIdentifierInput!
    $condition: ModelDocumentIdentifierConditionInput
  ) {
    deleteDocumentIdentifier(input: $input, condition: $condition) {
      id
      title
      owner {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      filename
      location
      status
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      reminderInterval
      createdAt
      updatedAt
      registryDocumentIdentifiersId
      documentIdentifierOwnerId
    }
  }
`;
export const createDocumentIdentifierRequest = /* GraphQL */ `
  mutation CreateDocumentIdentifierRequest(
    $input: CreateDocumentIdentifierRequestInput!
    $condition: ModelDocumentIdentifierRequestConditionInput
  ) {
    createDocumentIdentifierRequest(input: $input, condition: $condition) {
      id
      title
      requester {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      filename
      location
      status
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      reminderInterval
      requestStatus
      createdAt
      updatedAt
      registryDocumentIdentifierRequestsId
      documentIdentifierRequestRequesterId
      owner
    }
  }
`;
export const updateDocumentIdentifierRequest = /* GraphQL */ `
  mutation UpdateDocumentIdentifierRequest(
    $input: UpdateDocumentIdentifierRequestInput!
    $condition: ModelDocumentIdentifierRequestConditionInput
  ) {
    updateDocumentIdentifierRequest(input: $input, condition: $condition) {
      id
      title
      requester {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      filename
      location
      status
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      reminderInterval
      requestStatus
      createdAt
      updatedAt
      registryDocumentIdentifierRequestsId
      documentIdentifierRequestRequesterId
      owner
    }
  }
`;
export const deleteDocumentIdentifierRequest = /* GraphQL */ `
  mutation DeleteDocumentIdentifierRequest(
    $input: DeleteDocumentIdentifierRequestInput!
    $condition: ModelDocumentIdentifierRequestConditionInput
  ) {
    deleteDocumentIdentifierRequest(input: $input, condition: $condition) {
      id
      title
      requester {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      filename
      location
      status
      registry {
        id
        name
        description
        documentIdentifiers {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            createdAt
            updatedAt
            registryDocumentIdentifiersId
            documentIdentifierOwnerId
          }
          nextToken
        }
        documentIdentifierRequests {
          items {
            id
            title
            filename
            location
            status
            reminderInterval
            requestStatus
            createdAt
            updatedAt
            registryDocumentIdentifierRequestsId
            documentIdentifierRequestRequesterId
            owner
          }
          nextToken
        }
        registryType
        members {
          items {
            id
            employeeId
            role
            createdAt
            updatedAt
            registryMembersId
          }
          nextToken
        }
        registryOwner {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        registryRegistryOwnerId
      }
      reminderInterval
      requestStatus
      createdAt
      updatedAt
      registryDocumentIdentifierRequestsId
      documentIdentifierRequestRequesterId
      owner
    }
  }
`;
export const createKnowledgeManagementAdmins = /* GraphQL */ `
  mutation CreateKnowledgeManagementAdmins(
    $input: CreateKnowledgeManagementAdminsInput!
    $condition: ModelKnowledgeManagementAdminsConditionInput
  ) {
    createKnowledgeManagementAdmins(input: $input, condition: $condition) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateKnowledgeManagementAdmins = /* GraphQL */ `
  mutation UpdateKnowledgeManagementAdmins(
    $input: UpdateKnowledgeManagementAdminsInput!
    $condition: ModelKnowledgeManagementAdminsConditionInput
  ) {
    updateKnowledgeManagementAdmins(input: $input, condition: $condition) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteKnowledgeManagementAdmins = /* GraphQL */ `
  mutation DeleteKnowledgeManagementAdmins(
    $input: DeleteKnowledgeManagementAdminsInput!
    $condition: ModelKnowledgeManagementAdminsConditionInput
  ) {
    deleteKnowledgeManagementAdmins(input: $input, condition: $condition) {
      id
      employeeId
      employee {
        id
        email
        firstName
        lastName
        managerId
        manager {
          id
          email
          firstName
          lastName
          managerId
          manager {
            id
            email
            firstName
            lastName
            managerId
            level
            role
            createdAt
            updatedAt
            owner
          }
          level
          role
          groupMemberships {
            nextToken
          }
          groupManagerships {
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        level
        role
        groupMemberships {
          items {
            id
            groupId
            employeeId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        groupManagerships {
          items {
            id
            groupId
            employeeId
            actionsAllowed
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
