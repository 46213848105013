import * as React from 'react'

import { API, graphqlOperation } from 'aws-amplify'

import * as queries from '../../../graphql/queries'

import {
  Container,
  Header,
  Input,
  Button
} from '../../../aws-ui-components'

class KMSSearchComponent extends React.Component {
  state = {
    didSearch: "",
    error: "",
    didInfo: ""
  }

  findDid = async () => {
    this.setState({ loading: true })
    if (this.verifyDid(this.state.didSearch) === false) {
      this.setState({ error: "Invalid DID", loading: false })
    } else {
      const result = await API.graphql(graphqlOperation(queries.getDataWithAuthorisationFunction, {
        input: {
          requesterId: this.props.employee.id,
          dataSubjectId: "",
          operation: 'findDid',
          params: JSON.stringify({
            didToFind: this.state.didSearch
          })
        }
      }))
      if (result.data.getDataWithAuthorisationFunction.status === "ERROR") {
        this.setState({ error: result.data.getDataWithAuthorisationFunction.message, didInfo: "", loading: false })
      } else {
        this.setState({ didInfo: result.data.getDataWithAuthorisationFunction.message, error: "", loading: false })
      }
    }
  }

  verifyDid = (did) => {
    const regex = new RegExp('([A-Z, 0-9]){3}([A-Z]){2}([0-9]){4}([a-z]?)');
    return regex.test(did)
  }

  render() {
    return (
      <Container
        header={
          <Header
            variant="h2"
            description="Search for a specific DID across all registries"
          >
            DID Searcher
          </Header>
        }
      >
        <div style={{ display: 'flex' }}>
          <div style={{ width: '30em', marginRight: '1em' }}>
            <Input
              onChange={({ detail }) => this.setState({ didSearch: detail.value })}
              value={this.state.didSearch}
              placeholder={"Enter a full DID here ... e.g. 'AW1SA0001'"}
            />
          </div>
          <Button variant='primary' onClick={() => this.findDid()} loading={this.state.loading}>Search</Button>
        </div>
        {
          this.state.error && <div style={{ color: 'red' }}>{this.state.error}</div>
        }
        {/* TODO: PROPERLY DISPLAY DID INFO */}
        {
          this.state.didInfo && <div style={{ color: 'green', whiteSpace: 'pre-wrap' }}>{JSON.stringify(JSON.parse(this.state.didInfo), null, 4)}</div>
        }
      </Container>
    )
  }



}

export default KMSSearchComponent