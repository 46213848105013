import React, { useEffect, useState } from "react";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import { GetOrCreateUser } from "../helpers/authHelpers";

export default () => {

  const [employee, setEmployee] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const employee = await GetOrCreateUser();
      setEmployee(employee)
    }
    if (!employee) {
      fetchData();
    }
  });

  if (!employee) {
    return <div>Loading ...</div>
  }


  return (
    <div>
      <TopNavigation
        identity={{
          href: "#"
        }}
        utilities={[
          {
            type: "button",
            text: "Wiki",
            href: "https://w.amazon.com/bin/view/Fully_Automated_SA_Tool/",
            external: true,
            externalIconAriaLabel: " (opens in a new tab)"
          },
          {
            type: "menu-dropdown",
            iconName: "settings",
            ariaLabel: "Settings",
            title: "Settings",
            items: [
              {
                id: "settings-org",
                text: "Organizational settings"
              },
              {
                id: "settings-project",
                text: "Project settings"
              }
            ]
          },
          {
            type: "menu-dropdown",
            text: employee.firstName,
            description: employee.email,
            iconName: "user-profile",
            items: [
              { id: "profile", text: "Profile", href: '#/profile-settings' },
              {
                id: "support-group",
                text: "Support",
                items: [
                  {
                    id: "feedback",
                    text: "Feedback",
                    href: "#",
                    external: true,
                    externalIconAriaLabel:
                      " (opens in new tab)"
                  }
                ]
              }

            ]
          }
        ]}
        i18nStrings={{
          searchIconAriaLabel: "Search",
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More"
        }}

      />


    </div>

  );
}