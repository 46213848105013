import * as React from "react";

import { API, graphqlOperation } from "aws-amplify";

import {
    SpaceBetween,
    Button,
    Modal,
    Box,
    Form,
    Input,
    DatePicker,
    FormField,
    Textarea,
    Autosuggest,
    Multiselect,
    Checkbox
} from '../../aws-ui-components';
import SimpleTable from '../../common-components/SimpleTable.jsx';
import LargeTextWrapper from '../../common-components/LargeTextWrapper.jsx';

import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'
import { LEADERSHIPPRINCIPLES, ROLEGUIDELINES } from '../../helpers/constants'

import MDEditor from '@uiw/react-md-editor';

const CONTENT_SELECTOR_OPTIONS = [
    {
        label: 'Main distribution properties',
        options: [
            { id: 'name', label: 'Name', editable: false },
            { id: 'description', label: 'Description', editable: true },
            { id: 'amount', label: 'Amount', editable: true },
            { id: 'timeRange', label: 'Time Range', editable: true },
            { id: 'impact', label: 'Impact', editable: true },
            { id: 'stakeholders', label: 'Stakeholders', editable: true },
            { id: 'loiGoalId', label: 'Goal', editable: true},
            { id: 'leadershipPrinciples', label: 'LPs', editable: true},
            { id: 'roleGuidelines', label: 'Role Guidelines', editable: true},
            { id: 'highlighted', label: 'Highlighted', editable: true},
        ]
    }
];


const PAGE_SELECTOR_OPTIONS = [
    { value: 10, label: '10 Achievements' },
    { value: 30, label: '30 Achievements' },
    { value: 50, label: '50 Achievements' }
];

const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['name', 'description', 'amount', 'impact', 'stakeholders', 'loiGoalId', 'leadershipPrinciples', 'roleGuidelines', 'timeRange', 'highlighted'],
    wrapLines: true,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: item => item.name,
        minWidth: '200px',
        sortingField: 'name'
    },
    {
        id: 'description',
        header: 'Description',
        cell: item => <LargeTextWrapper full_text={item.description} length={200}/>,
        minWidth: '200px',
        sortingField: 'description'
    },
    {
        id: 'amount',
        header: 'Amount',
        cell: item => item.amount,
        minWidth: '100px',
        sortingField: 'amount'
    },
    {
        id: 'impact',
        header: 'Impact',
        cell: item => item.impact,
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'impact'
    },
    {
        id: 'stakeholders',
        header: 'Stakeholders',
        cell: item => item.stakeholders,
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'stakeholders'
    },
    {
        id: 'loiGoalId',
        header: 'LoI Goal',
        cell: item => getGoalName(item),
        minWidth: '100px',
        sortingField: 'loiGoalId'
    },
    {
        id: 'leadershipPrinciples',
        header: 'LPs',
        cell: item => item.leadershipPrinciples?.map(lp => (<p key={lp}>{LEADERSHIPPRINCIPLES[lp]}</p>)),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'leadershipPrinciples'
    },
    {
        id: 'roleGuidelines',
        header: 'Role Guidelines',
        cell: item => item.roleGuidelines?.map(rg => (<p key={rg}>{ROLEGUIDELINES[rg]['title']}</p>)),
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'roleGuidelines'
    },
    {
        id: 'timeRange',
        header: 'Time range',
        cell: item => `${new Date(item.startDate).toLocaleDateString('en-UK')} to ${new Date(item.endDate).toLocaleDateString('en-UK')}`,
        minWidth: '50px',
        maxWidth: '100px',
        sortingField: 'timeRange'
    },
    {
        id: 'highlighted',
        header: 'Highlighted',
        cell: item => showHighlighted(item.highlighted),
        minWidth: '100px',
        sortingField: 'highlighted'
    },
];

const showHighlighted = (highlighted) => {
    if (highlighted) {
        return <span>&#9733;</span>
    }
}

const getGoalName = (item) => {
    if (item.goal) {
        return item.goal.name
    }
    return null
}

class AchievementLogTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            error: "",
            modalVisible: false,
            prefilledModalVisible: false,
            prefilledModalTitle: "",
            detailModalVisible: false,
            editingAchievement: false,
            cloningAchievement: true,
            // Form values:
            name: "",
            description: "",
            impact: "",
            stakeholders: "",
            leadershipPrinciples: [],
            startDate: "",
            endDate: "",
            amount: null,
            selectedLoIGoal: "",
            selectedLoIGoalId: "",
            roleGuidelines: [],
            selectedLoiGoalObject: null,
            highlighted: true,
            resetDistributions: false,
        };
        this.initial_state = this.state
    }

    updateState = async() => {
        this.setState({resetDistributions: true})
        this.setState(this.initial_state)
        this.props.updateData(this.props.employee)
    };

    validateFields = () => {
        // TODO Finish this function
        const { name, description, startDate, endDate } = this.state
        if ([name, description, startDate, endDate].includes("")){
            this.setState({error: "Please fill in all values"})
            return false
        }
        else {
            this.setState({error: ""})
            return true
        }
    };


    onSubmit = async (achievementId) => {
        // const valid = this.validateFields();
        // if (!valid) { return }
        const { name, description, impact, stakeholders, leadershipPrinciples, roleGuidelines, startDate, endDate, amount, selectedLoIGoalId, selectedLoiGoalObject, highlighted }  = this.state;
        console.log(selectedLoiGoalObject)
        let operationResponse = "";
        if (this.state.editingAchievement === false){
            // Create an achievement
            var input = {
                name,
                description,
                leadershipPrinciples: leadershipPrinciples.map(a => a.value),
                roleGuidelines: roleGuidelines.map(a => a.value),
                impact,
                stakeholders,
                startDate,
                endDate,
                amount: parseInt(amount),
                highlighted: highlighted,
                employeeId: this.props.employee.id
            }
            if (selectedLoIGoalId !== "" && selectedLoIGoalId !== null) { 
                input.loiGoalId = selectedLoIGoalId
                input.goalId = selectedLoiGoalObject.goal.id
            }
            operationResponse = await API.graphql(graphqlOperation(mutations.createAchievement, {input: input}));
        } else if (achievementId !== null){
            var input = {
                id: achievementId,
                name,
                description,
                impact,
                stakeholders,
                leadershipPrinciples: leadershipPrinciples.map(a => a.value),
                roleGuidelines: roleGuidelines.map(a => a.value),
                startDate,
                endDate,
                amount: parseInt(amount),
                highlighted,
                employeeId: this.props.employee.id,
                loiGoalId: null,
                goalId: null
            }
            if (selectedLoIGoalId !== "" && selectedLoIGoalId !== null) { 
                input.loiGoalId = selectedLoIGoalId
                input.goalId = selectedLoiGoalObject.goal.id
            }
            operationResponse = await API.graphql(graphqlOperation(mutations.updateAchievement, {input: input}));
        }
        if (operationResponse.data){
            await this.updateState()
        }
    };


    onChange = (field, event) => {
        this.setState({[field]: event.detail.value})
    };


    deleteAchievement = async (achievementId) => {
        const confirmed = confirm("Are you sure you want to delete this achievement?")
        if (!confirmed) { return }

        await API.graphql(graphqlOperation(
            mutations.deleteAchievement, {
                input: {
                    id: achievementId,
                }
            }));
        await this.updateState()
    };

    formatLeadershipPrinciplesUI = (leadershipPrinciplesEnums) => {
        /*
            Formats the array to format that can be displayed by the UI. 
            Input: leadership_principles enums e.g. think_big
        */
        const leadershipPrinciplesDict = Object.entries(LEADERSHIPPRINCIPLES).map(item => ({label: item[1], value: item[0]}))
        return leadershipPrinciplesDict.filter(obj => leadershipPrinciplesEnums?.find(sp => sp === obj.value));
    }

    formatRoleGuidelines = (roleGuidelines) => {
        const roleGuidelineDict = Object.entries(ROLEGUIDELINES).map(item => ({
            label: item[1]['title'],
            description: item[1]['description'],
            value: item[0]
        }))
        return roleGuidelineDict.filter(obj => roleGuidelines?.find(sp => sp === obj.value))
    }

    editAchievement = async(achievementId) => {
        // const loiGoalItem = this.state.loiGoals.find(item => item.id === loi_goal_id)
        const resp = await API.graphql(graphqlOperation(queries.getAchievement, {id: achievementId}))
        const achievementData = resp.data.getAchievement;
        const leadershipPrinciples = this.formatLeadershipPrinciplesUI(achievementData.leadershipPrinciples);
        this.setState({
            prefilledModalTitle: "Editing Achievement",
            prefilledModalVisible: true,
            editingAchievement: true,
            id: achievementId,
            name: achievementData.name,
            description: achievementData.description,
            impact: achievementData.impact,
            stakeholders: achievementData.stakeholders,
            amount: achievementData.amount,
            startDate: achievementData.startDate,
            endDate: achievementData.endDate,
            highlighted: achievementData.highlighted,
            leadershipPrinciples: leadershipPrinciples,
            roleGuidelines: this.formatRoleGuidelines(achievementData.roleGuidelines),
            selectedLoIGoalId: achievementData.loiGoalId || "",
            selectedLoiGoalObject: achievementData.loiGoal || null,
            selectedLoIGoal: achievementData.loiGoal?.goal.name || "",
        })
    };

    cloneAchievement = async(achievementId) => {
        // const loiGoalItem = this.state.loiGoals.find(item => item.id === loi_goal_id)
        const resp = await API.graphql(graphqlOperation(queries.getAchievement, {id: achievementId}))
        const achievementData = resp.data.getAchievement;
        const leadershipPrinciples = this.formatLeadershipPrinciplesUI(achievementData.leadershipPrinciples);
        this.setState({
            prefilledModalTitle: "Cloning Achievement",
            prefilledModalVisible: true,
            editingAchievement: false,
            name: `${achievementData.name} - clone`,
            description: achievementData.description,
            impact: achievementData.impact,
            stakeholders: achievementData.stakeholders,
            amount: achievementData.amount,
            startDate: achievementData.startDate,
            endDate: achievementData.endDate,
            highlighted: achievementData.highlighted,
            leadershipPrinciples: leadershipPrinciples,
            roleGuidelines: this.formatRoleGuidelines(achievementData.roleGuidelines),
            selectedLoIGoalId: achievementData.loiGoalId || "",
            selectedLoiGoalObject: achievementData.loiGoal || null,
            selectedLoIGoal: achievementData.loiGoal?.goal.name || "",
        })
    };

    actions = (selectedDistributions) => {
        const isOnlyOneSelected = selectedDistributions.length === 1;
        var goalsArray = []
        for (const loigoal of this.props.activeGoals) {
            var description = loigoal.goal.description
            if (description.length > 200) {
                description = loigoal.goal.description.slice(0, 200)
                description = description.concat(" ... ")
            }
            goalsArray.push({
                id: loigoal.id,
                value: loigoal.goal.name,
                description: description + " (total amount: " + loigoal.amount +")",
                leadershipPrinciples: loigoal.goal.leadershipPrinciples,
                roleGuidelines: loigoal.goal.roleGuidelines,
                goalObject: loigoal,
            })
        }
        const value = this.state.selectedLoIGoal;
        const modalForm = (
            <Form>
                <SpaceBetween direction="vertical" size="m">
                    <FormField label="Name" description="Required">
                        <Input
                            onChange={(event) => this.onChange('name', event)}
                            value={this.state.name}
                        />
                    </FormField>
                    <FormField label="Description" description="Required">
                        <MDEditor
                            value={this.state.description}
                            onChange={(event) => this.setState({description: event})}
                            height={300}
                        />
                    </FormField>
                    <FormField label="Impact (or learnings)" description="(Optional) Describe the impact (or learnings) of this achievement">
                        <Textarea value={this.state.impact} onChange={(event) => this.onChange('impact', event)}/>
                    </FormField>
                    <FormField label="Stakeholders" description="(Optional) Describe the stakeholders (and their roles) of this achievement.">
                        <Textarea value={this.state.stakeholders} onChange={(event) => this.onChange('stakeholders', event)}/>
                    </FormField>
                    <FormField label="Amount completed" description="Required - enter amount achieved. If linked to a goal, this number will be used to calculate progress">
                        <Input
                            type="number"
                            onChange={(event) => this.onChange('amount', event)}
                            value={this.state.amount}
                        />
                    </FormField>
                    <FormField label="Which goal is this achievement related to?" description="(Optional) Link to a LoI goal to track progress, leave empty if not linked to goal">
                        <Autosuggest
                            onChange={({detail}) => this.updateChosenGoal(detail.value, goalsArray)}
                            value={value}
                            options={goalsArray}
                            enteredTextLabel={value => `Use: "${value}"`}
                            ariaLabel="Search goals"
                            placeholder="Search for one of your goals"
                            empty="No matches found"
                            disabled={false}
                        />
                    </FormField>
                    <FormField label="Start date" description="Required - when did you start work for this achievement?">
                        <DatePicker
                            onChange={(event) => this.onChange('startDate', event)}
                            value={this.state.startDate}
                            openCalendarAriaLabel={selectedDate =>
                                "Choose Date" +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                    </FormField>
                    <FormField label="End date" description="Required - when did you finish this achievement?">
                        <DatePicker
                            onChange={(event) => this.onChange('endDate', event)}
                            value={this.state.endDate}
                            openCalendarAriaLabel={selectedDate =>
                                "Choose Date" +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                    </FormField>

                    <FormField label="Leadership Principles" description="(Optional) Link this achievement to one or more LPs">
                        <Multiselect
                            selectedOptions={this.state.leadershipPrinciples}
                            onChange={({detail}) =>
                                this.setState({leadershipPrinciples: detail.selectedOptions})
                            }
                            deselectAriaLabel={e => "Remove " + e.label}
                            options={Object.entries(LEADERSHIPPRINCIPLES).map(item => ({
                                label: item[1],
                                value: item[0]
                            }))}
                            placeholder="Choose options"
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                    <FormField label="Role Guidelines dimensions" description="(Optional) Link this achievement to one or more Role Guidelines dimensions"> 
                        <Multiselect 
                            selectedOptions={this.state.roleGuidelines}
                            onChange={({detail}) =>
                                this.setState({roleGuidelines: detail.selectedOptions})
                            }
                            deselectAriaLabel={e => "Remove " + e.label}
                            options={Object.entries(ROLEGUIDELINES).map(item => ({
                                label: item[1]['title'],
                                description: item[1]['description'],
                                value: item[0]
                            }))}
                            placeholder="Choose options"
                            selectedAriaLabel="Selected"
                            disabled={this.state.editingGroupGoal}
                        />
                    </FormField>
                    <FormField label="Highlight" description="By default, Managers only see highlighted achievements. They can view non-highlighted achievements by unchecking a filter. Use this feature to allow your manager to get a quick overview of your important achievements.">
                        <Checkbox
                            onChange={({ detail }) =>
                                this.setState({highlighted: detail.checked})
                            }
                            checked={this.state.highlighted}
                        >
                            Highlight achievement
                        </Checkbox>
                    </FormField>
            </SpaceBetween>
            </Form>
        );

        return (
            <SpaceBetween direction="horizontal" size="s">
                <Button
                    disabled={selectedDistributions.length !== 1}
                    onClick={() => this.cloneAchievement(selectedDistributions[0]?.id)}
                >
                    Clone
                </Button>
                <Button
                    disabled={selectedDistributions.length !== 1}
                    onClick={() => this.editAchievement(selectedDistributions[0]?.id)}
                >
                    Edit
                </Button>
                <Button
                    disabled={selectedDistributions.length !== 1 }
                    onClick={() => this.deleteAchievement(selectedDistributions[0]?.id)}
                >
                    Delete
                </Button>
                <Button onClick={async() => {this.updateState(); this.setState({modalVisible: true})}} variant="primary" >
                    Create new achievement
                </Button>
                <Modal
                    onDismiss={() => this.setState({modalVisible: false})}
                    visible={this.state.modalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{color: 'red'}}>{this.state.error}</div>
                            </Box>
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button onClick={() => this.setState({modalVisible: false})} variant="link">Cancel</Button>
                                    <Button variant="primary" onClick={this.onSubmit}>Submit</Button>
                                </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header="Add a new achievement"
                >
                    {modalForm}
                </Modal>
                <Modal
                    onDismiss={() => this.setState({prefilledModalVisible: false})}
                    visible={this.state.prefilledModalVisible}
                    closeAriaLabel="Close modal"
                    size="large"
                    footer={
                        <div>
                            <Box float="left">
                                <div style={{color: 'red'}}>{this.state.error}</div>
                            </Box>
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button onClick={() => this.setState({prefilledModalVisible: false})} variant="link">Cancel</Button>
                                    <Button variant="primary" onClick={() => this.onSubmit(this.state.id)}>Submit</Button>
                                </SpaceBetween>
                            </Box>
                        </div>
                    }
                    header={this.state.prefilledModalTitle}
                >
                    {modalForm}
                </Modal>
            </SpaceBetween>
        );
    }
    render() {
        const table_params = {
            dataItems: this.props.achievements,
            columns: COLUMN_DEFINITIONS,
            defaultPreferences: DEFAULT_PREFERENCES,
            headerActions: this.actions,
            headerTitle: "My Achievements",
            enableSearch: false,
            pageSelectorOptions: PAGE_SELECTOR_OPTIONS,
            contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
            customPreferenceOptions: CUSTOM_PREFERENCE_OPTIONS,
            selection: "single",
            resetDistributions: this.state.resetDistributions,
            booleanFilterField: "highlighted",
            booleanFilterFieldLabel: "Show highlighted only",
            loading: this.props.loading,
            empty: "No achievements logged yet"
        };
        return (
            <SimpleTable params={table_params}/>
        );
    }

    updateChosenGoal = (goalValue, goalArray) => {
        const goal = goalArray.find(obj => { return obj.value === goalValue});
        const goalId = goal?.id || null;
        const leadershipPrinciples = this.formatLeadershipPrinciplesUI(goal?.leadershipPrinciples) || null;
        this.setState({
            selectedLoIGoal: goalValue,
            selectedLoIGoalId: goalId,
            selectedLoiGoalObject: goal?.goalObject || null,
            leadershipPrinciples: leadershipPrinciples,
            roleGuidelines: this.formatRoleGuidelines(goal?.roleGuidelines),
        })
    }
}

export default AchievementLogTable
