exports.verifyDid = (did) => {
  const regex = new RegExp('([A-Z, 0-9]){3}([A-Z]){2}([0-9]){4}([a-z]?)');
  return regex.test(did)
}

exports.canManageRegistryDids = (employeeId, registry) => {
  return employeeId === registry.registryOwner.id || registry.members.items.find(item => item.employeeId === employeeId && item.role === 'librarian')
}

exports.reminderOptions = [
  { label: 'No reminder', value: 'no_reminder' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Yearly', value: 'yearly' }
]

exports.docStatusOptions = [
  { label: 'Draft', value: 'draft' },
  { label: 'Current', value: 'current'},
  { label: 'Deprecated', value: 'deprecated'}
]