import React, { useState, useEffect } from 'react';
import ServiceNavigation from '../../common-components/ServiceNavigation.jsx';
import {
  AppLayout,
  BreadcrumbGroup,
  HelpPanel,
  Icon
} from '../../aws-ui-components';
import BlogTable from './BlogTable';
import TopNav from '../../common-components/TopNav.jsx';
import Header from './Header.jsx';

export default function ContentAuditTool() {
  return (
    <div>
      <TopNav //Top Navigation panel
      />
      <AppLayout
        navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
        breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
        content={<Content />} // Main content on the page, defined below
        contentHeader={<Header />} //Content Header containing tool description
        contentType="default" // Sets default app layout settings for widths
        tools={Tools} // Tools panel content defined below
        navigationOpen={true}
        toolsHide={true}
      />

    </div>

  );
}

// Breadcrumb content
const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'FAST',
        href: '#/'
      },
      {
        text: 'Content Audit Tool',
        href: '#/content-audit-tool'
      }
    ]}
  />
);

// Main content area (fill it in with components!)
function Content(props) {

  const SORT = {
    ASC: 'ASC',
    DESC: 'DESC',
  }
  return (
    <BlogTable></BlogTable>
  )
}

// Help panel content
const Tools = (
  <HelpPanel
    header={<h2>CloudFront</h2>}
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html">
              What is Amazon CloudFront?
            </a>
          </li>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/GettingStarted.html">
              Getting started
            </a>
          </li>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html">
              Working with distributions
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      Amazon CloudFront is a web service that speeds up distribution of your static and dynamic web content, such as
      .html, .css, .js, and image files, to your users.
    </p>
  </HelpPanel>
);
