/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

 https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import {
  // import the components that you use in the file here
  AppLayout,
  BreadcrumbGroup,
  Container,
  Header,
  SpaceBetween
} from '../../../aws-ui-components';

import KMSTable from './DIDTable'
import PersonalDIDTable from './PersonalDIDTable'

import ServiceNavigation from '../../../common-components/ServiceNavigation';

import { API, graphqlOperation } from 'aws-amplify'

import { GetOrCreateUser } from '../../../helpers/authHelpers'
import * as queries from '../../../graphql/queries'


class RegistryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      registry: null,
      loading: false,
      isOwner: false,
      employee: null,

    }
  }

  componentDidMount = async () => {
    this.setState({ loading: true })
    const registryId = this.state.id
    const employee = await GetOrCreateUser()
    const registryDataRaw = await API.graphql(
      graphqlOperation(queries.getDataWithAuthorisationFunction, {
        input: {
          requesterId: employee.id,
          dataSubjectId: '',
          operation: 'getRegistry',
          params: JSON.stringify({
            registryId: registryId
          })
        }
      })
    )
    const registryData = JSON.parse(
      registryDataRaw.data.getDataWithAuthorisationFunction.queryReturn
    )
    const isOwner = registryData.registryOwner.id === employee.id
    this.setState({ registry: registryData, employee: employee, loading: false, isOwner })
  }

  render() {
    const Breadcrumbs = () => (
      <BreadcrumbGroup
        items={[
          {
            text: 'FAST',
            href: '#/'
          },
          {
            text: 'Knowledge Management System',
            href: '#/knowledge-management-system'
          },
          {
            text: 'Registry',
            href: `#/knowledge-management-system-registry/${this.state.id}`
          }
        ]}
      />
    );

    const RegistryInfoHeader = () => (
      <Container
        header={
          <Header
            variant="h2"
            description=""
          >
            Registry Info
          </Header>
        }
      >
        <ul>
          <li><b>Registry name: </b>{this.state.registry.name}</li>
          <li><b>Registry description: </b>{this.state.registry.description}</li>
          <li><b>Registry Type: </b>{this.state.registry.registryType}</li>
          <li><b>Registry Members: </b>{JSON.stringify(this.state.registry.members.items.map(item => `${item.employeeId} - (${item.role})`))}</li>
        </ul>
      </Container>
    )

    const Content = () => {
      if (this.state.registry === null) {
        return <Container><p id={this.state.id}>Loading registry ...</p></Container>
      }
      if (this.state.registry?.registryType === 'personal') {
        return <PersonalDIDTable id={this.state.id} employee={this.state.employee} loading={this.state.loading} registry={this.state.registry} isOwner={this.state.isOwner} />
      } else {
        return (
          <SpaceBetween size="xs">
            <RegistryInfoHeader/>
            <KMSTable id={this.state.id} employee={this.state.employee} loading={this.state.loading} registry={this.state.registry} isOwner={this.state.isOwner} />
          </SpaceBetween>
        )
      }
    }

    return (
      <AppLayout
        navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
        breadcrumbs={<Breadcrumbs />} // Breadcrumbs element defined below
        content={<Content />} // Main content on the page, defined below
        contentType="default" // Sets default app layout settings for widths
        navigationOpen={false}
        toolsHide={true}
      />
    );
  }
}

// Breadcrumb content


export default RegistryItem
